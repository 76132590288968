import * as Action from '../actions/registrations'
import * as ProjectAction from '../actions/projects'
import {PENDING, FULFILLED} from '../actions/constants';
import update from 'immutability-helper';
import {DELETE_IMAGE} from "../actions/registrations";

export function registrations(state = {
    pagination: {
        limit: 10,
        skip: 0,
        pagedItems: {}
    },
    items: {},
    images: {},
    fetching: false,
    fetched: false,

    exportItems: [],
    exportFetching: false,
    exportFetched: false,

    active: {
        item: null,
        logs: {
            fetching: false,
            fetched: false,
            items: []
        },
    },
    registrationCategoryFilter: null,
}, action) {
    switch (action.type) {

        case ProjectAction.SET_ACTIVE_PROJECT:
        case Action.CLEAR_CACHE:
            return update(state, {
                items: {$set: {}},
                fetched: {$set: false},
                fetching: {$set: false},
                pagination: {
                    $set: {
                        limit: 10,
                        skip: 0,
                        pagedItems: {}
                    }
                },
                active: {
                    item: {$set: null},
                    logs: {
                        fetching: {$set: false},
                        fetched: {$set: false},
                        items: {$set: []}
                    }
                },
                registrationCategoryFilter: {$set: null},
            });

        case Action.FETCH_REGISTRATIONS + PENDING:
            return Object.assign({}, state, {
                fetching: true,
            });

        case Action.FETCH_REGISTRATIONS + FULFILLED:
            return Object.assign({}, state, {
                fetching: false,
                fetched: true,
                items: action.payload
            });

        case Action.FETCH_REGISTRATIONS_FOR_EXPORT_START:
            return Object.assign({}, state, {
                exportItems: {$set: {}},
                exportFetching: true,
                exportFetched: false,
            });
        case Action.FETCH_REGISTRATIONS_FOR_EXPORT:
            return Object.assign({}, state, {
                exportFetching: false,
                exportFetched: true,
                exportItems: action.payload
            });

        case Action.SET_ACTIVE_PAGE:
            const newItems = state.pagination.pagedItems[state.pagination.limit * action.payload];
            return update(state, {
                pagination: {
                    skip: {$set: state.pagination.limit * action.payload},
                    pagedItems: {
                        [state.pagination.skip]: {$set: state.items}
                    }
                },
                items: {$set: newItems ? newItems : state.items},
                fetched: {$set: !!newItems}
            });
        case Action.FETCH_REGISTRATION_IMAGES + FULFILLED:
            return update(state, {
                images: {
                    [action.meta.registrationId]: {$set: action.payload}
                }
            });
        case Action.ADD_IMAGE:
            return update(state, {
                images: {
                    [action.meta.registrationId]: {
                        $push: [action.payload]
                    }
                }
            });
        case Action.DELETE_IMAGE:
            return update(state, {
                images: {
                    [action.payload.registrationId]: {
                        $splice: [[action.payload.imageIndex, 1]]
                    }
                }
            });
        case Action.UPDATE_REGISTRATION_OBJECT:
            return update(state, {
                items: {
                    [action.registration.id]: {
                        $set: action.registration
                    }
                }
            });
        case Action.SET_ACTIVE_REGISTRATION + FULFILLED:
            return update(state, {
                active: {
                    item: {$set: action.payload},
                    logs: {
                        fetched: {$set: false},
                        items: {$set: []}
                    }
                }
            });
        case Action.FETCH_VERSION_LOGS + PENDING:
            return update(state, {
                active: {
                    logs: {
                        fetching: {$set: true},
                    }
                }
            });
        case Action.FETCH_VERSION_LOGS + FULFILLED:
            return update(state, {
                active: {
                    logs: {
                        fetched: {$set: true},
                        fetching: {$set: false},
                        items: {$set: action.payload}
                    }
                }
            });
        case Action.SET_REGISTRATION_CATEGORY_FILTER:
            return update(state, {
                registrationCategoryFilter:
                    {$set: action.payload},
            });
        case Action.SET_REGISTRATION_SEARCH_FILTER:
            return update(state, {
                registrationSearchFilter:
                    {$set: action.payload},
            });

        default:
            return state;
    }

}