import React from 'react'
import GoogleMaps from './Map';

// const MapOverlayPoint = Parse.Object.extend("MapOverlayPoint");

export const parseHex = (hex) => {
    if (!hex) return {color: "", opacity: 0};
    return {
        color: hex.substr(0, 7),
        opacity: parseInt(hex.substr(7, 9) || "FF", 16) / 255
    }
}

export default class RegistrationMapOverlayBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeProjectId: props.project && props.project.id,
            points: props.map && this.parsePoints(props.map.points),
            polys: props.map && this.parsePolys(props.map.polys),
            // pointObjects: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps || !nextProps.map || !nextProps.map.points)return;
        if (this.state.activeProjectId !== nextProps.project.id ||
            this.state.points.length !== nextProps.map.points.length ||
            (nextProps.map.polys && this.state.polys.length !== nextProps.map.polys.length)) {
            let pointObjects = this.parsePoints(nextProps.map.points);
            let polyObjects = this.parsePolys(nextProps.map.polys);
            this.setState({
                activeProjectId: nextProps.project.id,
                points: pointObjects,
                polys: polyObjects
            });
        }

    }

    parsePoints(points) {

        let pointObjects = [];

        for (let i in points) {
            let point = points[i];
            pointObjects.push(this.pointToObject(point));
        }

        return pointObjects;

    }

    pointToObject(point) {
        return ({
            id: point.id,
            description: point.get("description") || " ",
            coordinates: {
                lat: point.get("coordinates") && point.get("coordinates").latitude,
                lng: point.get("coordinates") && point.get("coordinates").longitude,
            },
            type: {
                id: point.get("type") && point.get("type").id,
                name: point.get("type") && point.get("type").get("name"),
                icon: point.get("type") && point.get("type").get("image") && point.get("type").get("image").url(),
            },
            windparkId: point.get("windpark") && point.get("windpark").id,
            point: point,
        });
    }

    parsePolys(polys) {

        let polyObjects = [];

        for (let i in polys) {
            let poly = polys[i];
            polyObjects.push(this.polyToObject(poly));
        }

        return polyObjects;

    }

    polyToObject(poly) {
        let properties = {
            parseObject: poly,
            id: poly.id,
            description: poly.get("description") || " ",
            coordinates: poly.get("coordinates"),
            isPolygon: poly.get("isPolygon"),
            strokeWidth: poly.get("strokeWidth"),
            // strokeColor: poly.get("strokeColor") && poly.get("strokeColor").substr(0, 7),
            // strokeOpacity: poly.get("strokeColor") && parseInt(poly.get("strokeColor").substr(7, 9) || "FF", 16)/255,
            // fillColor: poly.get("fillColor") && poly.get("fillColor").substr(0, 7),
            // fillOpacity: poly.get("fillColor") && parseInt(poly.get("fillColor").substr(7, 9) || "FF", 16)/255,
        }
        if (poly.get("strokeColor")) {
            let stroke = parseHex(poly.get("strokeColor"));
            properties.strokeColor = stroke.color;
            properties.strokeOpacity = stroke.opacity;
        }
        if (poly.get("fillColor")) {
            let fill = parseHex(poly.get("fillColor"));
            properties.fillColor = fill.color;
            properties.fillOpacity = fill.opacity;
        }
        return properties;
    }

    render() {

        const zoomLevel = this.props.project && this.props.project.get("googleMapsZoomLevel");
        const googleMapsType = this.props.project && this.props.project.get("googleMapsType");

        return (
            <div>
                {this.props.project.id && (
                    <GoogleMaps
                        coordinates={this.props.coordinates || this.props.project.get("coordinates")}
                        markers={this.state.points}
                        polys={this.state.polys}
                        editMode={!!this.props.editMode}
                        zoomLevel={zoomLevel}
                        googleMapsType={googleMapsType}
                    />
                )}
            </div>);
    }
}