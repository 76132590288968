import React from 'react';
import {connect} from "react-redux";
import * as WPActions from "../actions/windparks";
import {Alert, Button, ControlLabel, Form, FormControl, FormGroup, Modal} from "react-bootstrap";
import spinnerImage from "../resources/images/spinner.gif";

class ProjectWindparks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            newWindparkName: "",
            // newWindparkDescription: "",
            editing: null,
        };
    }

    close() {
        this.setState({
            modalOpen: false,
            newWindparkName: "",
            // newWindparkDescription: "",
            editing: null,
        });
    }

    componentWillMount() {
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        if ((this.props.project && this.props.project.id) === (nextProps.project && nextProps.project.id)) return;
        let projectId = nextProps.project && nextProps.project.id;
        if (projectId) {
            this.loadData(projectId);
        }
    }

    loadData(projectId) {

        if (!projectId) projectId = this.props.project && this.props.project.id;
        if (projectId) {
            this.props.fetchWindparks(projectId);
        }

    }

    editWindpark(windpark) {

        this.setState({
            editing: windpark,
            newWindparkName: windpark.get('name'),
            // newWindparkDescription: windpark.get('descriptionText'),
            modalOpen: true,
        })
    }

    addNewWindpark() {
        this.close()
        if (this.state.editing) {
            let windpark = this.state.editing;
            windpark.set("name", this.state.newWindparkName);
            // windpark.set("descriptionText", this.state.newWindparkDescription);
            this.props.editWindpark(this.props.project.id, windpark);
            this.setState({editing: null})
        } else {
            this.props.addNewWindpark(this.props.project.id, this.state.newWindparkName);
        }

    }

    removeWindpark() {
        if (window.confirm("Are you sure you want to delete this windpark? (This can be undone by a system administrator)")) {
            this.close()
            let windpark = this.state.editing;
            windpark.set("deleted", true);
            this.props.editWindpark(this.props.project.id, windpark);
            this.setState({editing: null})
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {

        let {windparks} = this.props;
        let {modalOpen, newWindparkName, newWindparkDescription, editing} = this.state;

        return <>
            <Modal show={modalOpen} onHide={() => this.close()}>
                <Form onSubmit={(e) => {e.preventDefault(); this.addNewWindpark(); return false;}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Windpark details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Windpark name</ControlLabel>
                            <FormControl
                                type="text"
                                name="newWindparkName"
                                value={newWindparkName}
                                placeholder="Example project"
                                onChange={this.handleChange.bind(this)}
                            />
                        </FormGroup>
                        {/*<FormGroup*/}
                        {/*    controlId="formBasicText"*/}
                        {/*>*/}
                        {/*    <ControlLabel>Description</ControlLabel>*/}
                        {/*    <FormControl*/}
                        {/*        type="text"*/}
                        {/*        name="newWindparkDescription"*/}
                        {/*        value={newWindparkDescription}*/}
                        {/*        placeholder="(optional)"*/}
                        {/*        onChange={this.handleChange.bind(this)}*/}
                        {/*    />*/}
                        {/*</FormGroup>*/}
                    </Modal.Body>

                    <Modal.Footer>
                        {editing && <Button className="pull-left" bsStyle="danger" onClick={() => this.removeWindpark()}>Delete windpark</Button>}
                        <Button onClick={() => this.close()}>Cancel</Button>
                        <Button bsStyle="primary" type="submit">Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {!windparks || windparks.fetching && <img src={spinnerImage} alt="Loading..." className="spinner-centered" />}

            <table className="table table-striped">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Windpark</th>
                    <th style={{width: 100}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {windparks && windparks.items && windparks.items.filter(a => !a.get("deleted")).map(item => <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.get("name")}</td>
                    <td>
                        <Button onClick={() => this.editWindpark(item)}>Edit</Button>
                    </td>
                </tr>)}
                </tbody>
            </table>

            <br/><br/><br/>

            <div className="card-body">

                <Button bsStyle="primary" onClick={() => this.setState({modalOpen: true})}>Add Windpark</Button>

            </div>

        </>
    }

}


export default connect(state => ({
    windparks: state.windparks.items[state.projects.active],
}), dispatch => ({
    fetchWindparks: projectId => dispatch(WPActions.fetch(projectId)),
    addNewWindpark: (projectId, name, description) => dispatch(WPActions.newWindpark(projectId, name, description)),
    editWindpark: (projectId, wpId, name) => dispatch(WPActions.editWindpark(projectId, wpId, name)),
}))(ProjectWindparks);

