import React from "react";
import {connect} from "react-redux";
import * as RegistrationAction from "../actions/registrations";
import {Glyphicon} from "react-bootstrap";
import moment from "moment";
import {CALLBACK_VALUES} from "./CallbackSelector";

const keys = {
    "priority" : "Priority",
    "callback" : "Call back",
    "description" : "Description",
    "type" : "Type",
    "reaction" : "Reaction",
    "registrationCategory": "Type",
    "registrationPriority": "Priority",
};

class VersionLogOverview extends React.Component{

    constructor(){
        super();

        this.state = {
            visible: true
        }
    }

    loadData(){
        this.props.fetch(this.props.registration)
    }

    getValue(item, key){
        if(item.get(key) && item.get(key).id){
            return item.get(key).get("name") || "unknown";
            // switch(item.get(key).className){
            //     case "RegistrationType":
            //         return this.props.registrationTypes[item.get(key).id].get("name");
            //     case "RegistrationCategory":
            //         return this.props.registrationTypes[item.get(key).id].get("name");
            //     case "RegistrationPriority":
            //         return item.get(key).get("name");
            //     default:
            //         return "unknown";
            // }
        }

        switch(key){
            // case "priority":
            //     return PRIORITY_TYPES[item.get(key)];
            case "callback":
                return CALLBACK_VALUES[item.get(key)];
            default:
                return item.get(key);
        }

    }

    toggle(){
        this.setState({visible: !this.state.visible})
    }

    render(){
        const {items} = this.props.logs;

        let tableBody = items.map((item, i) => {
            let row = [];
            row.push(
                <tr key={item.id} className="light-blue-background">
                    <th colSpan={2}>
                        {item.get("user").get("userInfo").get("name")}
                    </th>
                    <th colSpan={2} style={{textAlign: "right"}}>
                        {moment(item.get("createdAt")).format("D MMM YY, HH:mm")}
                    </th>
                </tr>
            );

            for (let column of item.get("changedColumns")){
                let newValue = this.getValue(i > 0 ? items[i-1] : this.props.registration, column);
                console.log(typeof newValue);
                if (newValue && typeof newValue !== "string")newValue = newValue.toString();

                console.log(newValue);
                row.push(
                    <tr key={item.id + column}>
                        <td><strong>{keys[column]}</strong></td>
                        <td>{this.getValue(item, column)}</td>
                        <td style={{color: "#ccc"}}><Glyphicon glyph="chevron-right" /></td>
                        <td>{newValue}</td>
                    </tr>
                )
            }
            if(item.get("changedColumns").length === 0){
                row.push(
                    <tr key="no-changes">
                        <td colSpan={4} style={{textAlign:"center"}}><em>no changes</em></td>
                    </tr>
                )
            }
            return row;
        });

        return <div>

            <div className="toolbar">
                <div className="title">
                    <strong>Change Log</strong>
                </div>
                {!this.props.logs.fetched ? (
                    <div className="pull-right" style={{cursor: "pointer", marginTop: "5px"}}>
                        <Glyphicon glyph="chevron-down"
                            onClick={this.loadData.bind(this)} />
                    </div>
                ) : (
                    <div className="pull-right" style={{cursor: "pointer", marginTop: "5px"}}>
                        <Glyphicon glyph={this.state.visible ? "chevron-up" : "chevron-down"}
                                   onClick={this.toggle.bind(this)} />
                    </div>
                )}
            </div>

            <table className="table">
                <tbody>
            {this.props.logs.fetched && this.state.visible && tableBody}
                </tbody>
            </table>
        </div>
    }
}

export default connect(state => ({
    logs: state.registrations.active.logs,
    registrationTypes: state.registrationTypes.items,
}), dispatch => ({
    fetch: (registration) => dispatch(RegistrationAction.fetchVersionLogs(registration))
}))(VersionLogOverview);