import React from 'react';
import {connect} from "react-redux";
import * as Action from '../actions/registrationTypes'
import {Glyphicon, Label, OverlayTrigger, Tooltip} from "react-bootstrap";


class RegistrationOpenSelector extends React.Component {

    handleChange(event) {
        console.log("Open = ", event.target.value == "open");
        this.props.registration.set("open", event.target.value == "open");
    }

    render() {

        const {types, editing, registration} = this.props;
        let open = registration.get("open");

        return editing ? <>
                <select defaultValue={open} onChange={(e) => {
                    this.handleChange(e)
                }}>
                    <option value="open">
                        Open
                    </option>
                    <option value="close">
                        Closed
                    </option>
                </select>
            </>
            : <>
                {open ? "Open" : "Closed"}
            </>

    }
}

export default RegistrationOpenSelector;