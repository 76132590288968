import React from 'react';
import Parse from 'parse';
import {connect} from 'react-redux';
import {Button, Glyphicon, Label,} from 'react-bootstrap';
import * as Action from '../actions/projects';
import ModalProject from '../modalProject.js';
import LinkContainer from "../components/LinkContainer";


class ProjectsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            admin: false,
            showModal: false,
        };
        this.newProject = this.newProject.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        this.loadData();
    }

    // componentDidUpdate() {
    //     this.loadData();
    // }

    loadData() {
        this.props.fetchProjectsIfNeeded();
    }

    componentDidMount() {

        let _this = this;
        let currentUser = Parse.User.current();
        if (currentUser !== null) {
            currentUser.fetch().then(user => {
                _this.setState({admin: user.get("admin")})
            })
        }
    }

    newProject() {
        this.setState({showModal: true});
    }

    closeModal(project) {
        if (project !== undefined && project.id !== undefined) {
            let projects = this.state.projects || [];
            project.name = project.get("name");
            projects.push(project);
            this.setState({projects: projects, showModal: false});
        } else {
            this.setState({showModal: false});
        }
    }

    render() {
        const {projects} = this.props;
        let orderedProjects = Object.values(projects.items).sort((a, b) => a.get("name").localeCompare(b.get("name")));

        return (
            <div className="card">
                <div className="card-heading">
                    <h2>
                        Projects
                        {this.state.admin &&
                            <Button bsStyle="primary" className="pull-right" onClick={this.newProject}>
                                <Glyphicon glyph="plus"/>&nbsp;
                                New project
                            </Button>
                        }
                    </h2>
                </div>
                {projects.fetched &&
                orderedProjects.length > 0 ? (
                    <table className="table table-striped">
                        <tbody>
                        {orderedProjects.map((project) => {
                            return (
                                <tr key={project.id} className="project clickable">
                                    <LinkContainer to={"project/" + project.id}>
                                        <td style={{width: 100}}>
                                            {project.get("icon") != null &&
                                                <img src={project.get("icon").url()} style={{width: 100}}/>
                                            }
                                        </td>
                                        <td className="clickable">
                                            {/*{project.isHSEManager &&*/}
                                            {/*<LinkContainer to={"edit-project/" + project.id} className="pull-right">*/}
                                            {/*<Button>manage users</Button>*/}
                                            {/*</LinkContainer>*/}
                                            {/*}*/}
                                            <strong>{project.get("name")}</strong><br/>
                                            {project.isHSEManager && (
                                                <Label bsStyle="primary">Responsible Person</Label>
                                            )}{" "}
                                            {project.isAdmin && (
                                                <Label bsStyle="warning">Administrator</Label>
                                            )}{" "}
                                            {project.role && (
                                                <Label bsStyle="success">{project.role.get("name")}</Label>
                                            )}

                                        </td>
                                    </LinkContainer>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                ) : (
                    <div className="card-body">
                        <em>No projects added yet.<br/><br/></em>
                    </div>
                )

                }
                <ModalProject show={this.state.showModal} close={this.closeModal}/>
            </div>
        );
    }

};


export default connect(state => ({
    projects: state.projects
}), dispatch => ({
    fetchProjectsIfNeeded: () => dispatch(Action.fetchProjectsIfNeeded())
}))(ProjectsPage);