import React from "react";
import ImageLoader from 'react-imageloader';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

import {connect} from 'react-redux';
import spinnerImage from "./resources/images/spinner.gif";
import {Button} from "react-bootstrap";
import * as RegistrationAction from "./actions/registrations";
import {DELETE_IMAGE} from "./actions/registrations";

class FullImageGallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            registrationId: null,
            largeImage: 0,
            lightboxOpen: false,
            imageUrls: [],
        };
        this.setLargeImage = this.setLargeImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.registration.id !== this.state.registrationId) {
            this.setState({
                largeImage: 0,
                registrationId: nextProps.registration.id,
            })
        }
    }

    setLargeImage(image) {
        this.setState({largeImage: image})
    }

    preloader() {
        return <img src={spinnerImage} alt="Loading..." className="spinner-centered"/>;
    }

    openLightbox() {
        this.setState({
            lightboxOpen: true
        })
    }

    render() {

        let {images, registration} = this.props;
        let {largeImage} = this.state;

        let showImages = !!images && !!images[registration.id || "new"];

        images = showImages ? images[registration.id || "new"] : null;
        let imageUrls = (images || []).map(image => {
            return {src: image.get("file").url()};
        });
        // console.log(imageUrls);

        // images[(largeImage + 1) % images.length].get("file").url()

        return <>

            {showImages ? (
                <div>
                    {images.length > 1 && images.map((image, index) => {
                        let url = image.id ?
                            // (image.get("file") && image.get("file").url()) :
                            (image.get("thumbnail") && image.get("thumbnail").url()) :
                            image.get("localUrl");
                        return (
                            <div key={"image" + index} className="registration-thumbnail"
                                 style={{backgroundImage: 'url(' + url + ')'}}
                                 onClick={() => {
                                     if (image.id) {
                                         this.setLargeImage(index);
                                     } else {
                                         if (window.confirm("Do you want to delete this image?")) this.props.deleteImage(index, registration.id);
                                     }
                                 }}/>
                        )
                    })}
                    <div style={{clear: "both"}}>
                        {images[largeImage] && (
                            <ImageLoader
                                className="registration-image"
                                // src={images[largeImage].get("file") && images[largeImage].get("file").url()}
                                src={
                                    images[largeImage].id ?
                                    images[largeImage].get("thumbnail") && images[largeImage].get("thumbnail").url() :
                                    images[largeImage].get("localUrl")
                                }
                                preloader={this.preloader}
                                imgProps={{onClick: this.openLightbox}}
                            />
                            // <img id="large-image" src={largeImage.get("file").url()} alt=""  />
                        )}
                    </div>


                    <Lightbox
                        open={this.state.lightboxOpen}
                        close={() => this.setState({ lightboxOpen: false })}
                        index={largeImage}
                        slides={imageUrls}
                    />

                    {/*{this.state.lightboxOpen &&
                        // <Lightbox
                        //     mainSrc={images[largeImage].get("file").url()}
                        //     nextSrc={images.length > 1 ? images[(largeImage + 1) % images.length].get("file").url() : undefined}
                        //     prevSrc={images.length > 1 ? images[(largeImage + images.length - 1) % images.length].get("file").url() : undefined}
                        //     onCloseRequest={() => this.setState({lightboxOpen: false})}
                        //     onMovePrevRequest={() => this.setState({
                        //         largeImage: (largeImage + images.length - 1) % images.length,
                        //     })}
                        //     onMoveNextRequest={() => this.setState({
                        //         largeImage: (largeImage + 1) % images.length,
                        //     })}
                        // />
                    {/*}*/}

                </div>
            ) : (
                <div></div>
            )}
            <br />
        </>
    }
}

export default connect(state => ({
    images: state.registrations.images
}), dispatch => ({
    deleteImage: (imageIndex, registrationId) => dispatch({
        type: DELETE_IMAGE,
        payload: {imageIndex, registrationId}
    }),
}))(FullImageGallery);