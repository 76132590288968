import React from 'react';
import ReactClass from 'create-react-class';
import {Button, Modal, Form, Alert} from 'react-bootstrap';
import {connect} from "react-redux";
import * as UserAction from "./actions/users";

let component = ReactClass({

    close() {
        this.props.cancelAddUserToProject();
    },

    save(event) {
        event.preventDefault();
        this.props.addMembership(this.props.user, this.props.project);
        return false;
    },

    render() {
        let {user, mail} = this.props;
        return (
            <Modal show={true} onHide={this.close}>
                <Form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title>Adding user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {user.get("username") !== mail && <Alert bsStyle="warning">
                            Note: No user has been found with e-mail address '{mail}', adding user with e-mail address '{user.get("username")}' instead.
                        </Alert>}

                        The following user will be added to this project:<br /><br />
                    </Modal.Body>
                        <table className="table table-striped table-condensed">
                            <tbody>
                            <tr>
                                <td><strong>Name</strong></td>
                                <td>{user.get("userInfo").get("name")}</td>
                            </tr>
                            <tr>
                                <td><strong>E-mail address</strong></td>
                                <td>{user.get("username")}</td>
                            </tr>
                            <tr>
                                <td><strong>Phone number</strong></td>
                                <td>{user.get("userInfo").get("phonenumber")}</td>
                            </tr>
                            </tbody>
                        </table><br /><br />

                    <Modal.Footer>
                        <strong className="pull-left">Continue?</strong>
                        <Button onClick={this.close}>Cancel</Button>
                        <Button bsStyle="primary" type="submit">Continue</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    },

});

export default connect(state => ({}
), dispatch => ({
    cancelAddUserToProject: () => dispatch(UserAction.cancelAddUserToProject()),
    addMembership: (user, project) => dispatch(UserAction.addMembership(user, project))
}))(component);