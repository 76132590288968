import Parse from 'parse';

export const FETCH_REGISTRATION_TYPES = "FETCH_REGISTRATION_TYPES";
export const SET_ACTIVE_TYPE = "SET_ACTIVE_TYPE";
export const SET_TYPE_ACTIVITY = "SET_TYPE_ACTIVITY";

export function fetch(force) {
    return (dispatch, state) => {
        let {registrationTypes} = state();
        if ((!registrationTypes.fetched && !registrationTypes.fetching) || force) {
            dispatch({
                type: FETCH_REGISTRATION_TYPES,
                payload: new Parse.Query("RegistrationCategory")
                    .ascending("order")
                    .include("priority1")
                    .include("priority2")
                    .include("priority3")
                    .find()
                    .then(objects => objects.reduce((o, n) => {
                        o[n.id] = n;
                        return o;
                    }, {}))
            });
        }
    }
}

export function setActiveType(type) {
    return {
        type: SET_ACTIVE_TYPE,
        payload: type
    };
}
export function setTypeActivity(type, activity, active) {

    return (dispatch, state) => {
        let activities = type.get("registrationActivities") || [];
        if (active && activities.indexOf(activity.id) === -1) {
            activities.push(activity.id);
        } else if (!active && activities.indexOf(activity.id) !== -1) {
            delete activities[activities.indexOf(activity.id)];
        }
        type.set("registrationActivities", activities.filter(a => !!a));

        type.save().then(() => {
            dispatch(fetch(true));
        });
    }
}
export function setTypeDanger(type, danger, active) {

    return (dispatch, state) => {
        let dangers = type.get("registrationDangers") || [];
        if (active && dangers.indexOf(danger.id) === -1) {
            dangers.push(danger.id);
        } else if (!active && dangers.indexOf(danger.id) !== -1) {
            delete dangers[dangers.indexOf(danger.id)];
        }
        type.set("registrationDangers", dangers.filter(a => !!a));

        type.save().then(() => {
            dispatch(fetch(true));
        });
    }
}