import Parse from 'parse';

export default {
    isAuthenticated: Parse.User.current() !== null,


    authenticate(email, password) {

        let _this = this;

        return Parse.User.logIn(email, password, {
            success: function(user) {
                _this.isAuthenticated = Parse.User.current() !== null;

            },
            error: function(user, error) {
                console.log("Error: ", error);
            }
        });

    },

    signOut() {
        this.isAuthenticated = false;
        this.admin = undefined;
        return Parse.User.logOut().then(() => {
            window.location.reload();
        })
    }

}