import React from 'react';
import {connect} from "react-redux";


class DangerSelector extends React.Component {

    handleChange(event) {
        let activitiesById = this.props.dangers.reduce((o, n) => {
            o[n.id] = n;
            return o;
        }, {});
        this.props.registration.set("registrationDanger", activitiesById[event.target.value]);
        this.forceUpdate();
    }

    render() {
        const {editing, registration, dangers} = this.props;
        let activeId = registration.get("registrationDanger") && registration.get("registrationDanger").id;
        return editing ? <>
            <select value={activeId || ""} onChange={(e) => {
                this.handleChange(e)
            }}>
                <option>None</option>
                {dangers.map((type, index) => {
                    return (
                        <option key={"act" + index} value={type.id}>
                            {type.get("name")}
                        </option>
                    )
                })}
            </select>
        </> : (
            <div>
                {registration.getDangerDescription("None")}
            </div>
        )

    }
}

export default connect(state => ({
        dangers: (state.registrationTypes.active && state.registrationTypes.active.get("registrationDangers") || [])
            .map(d => state.projects.settings.dangers.find(da => da.id === d))
            .filter(
                (d) => d && d.get("active")
            )
            .sort((a, b) => a.get("order") - b.get("order")),
    }),
    dispatch => ({})
)
(DangerSelector);