import Parse from 'parse';

export const FETCH_WINDPARKS = "FETCH_WINDPARKS";
export const NEW_WINDPARK = "NEW_WINDPARK";
export const EDIT_WINDPARK = "EDIT_WINDPARK";
export const REMOVE_WINDPARK = "REMOVE_WINDPARK";

export function fetch(projectId){
    return {
        type: FETCH_WINDPARKS,
        meta: {
            projectId
        },
        payload: new Parse.Query("Windpark")
            .equalTo("project", new Parse.Object('Project', { id: projectId }))
            .notEqualTo("deleted", true)
            .ascending("name")
            .find()
    };
}
export function newWindpark(projectId, name, descriptionText){
    return {
        type: NEW_WINDPARK,
        meta: {
            projectId
        },
        payload: new Parse.Object("Windpark")
            .set("project", new Parse.Object('Project', { id: projectId }))
            .set("name", name)
            // .set("descriptionText", descriptionText)
            .save()
    };
}
export function editWindpark(projectId, windpark){
    return {
        type: EDIT_WINDPARK,
        meta: {
            projectId
        },
        payload: windpark
            .save()
    };
}