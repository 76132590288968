import Parse from 'parse';

export const FETCH_ROLES = "FETCH_ROLES";
export const NEW_ROLE = "NEW_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const REMOVE_ROLE = "REMOVE_ROLE";

export function fetch(projectId) {
    return {
        type: FETCH_ROLES,
        meta: {
            projectId
        },
        payload: new Parse.Query("ProjectRole")
            .equalTo("project", new Parse.Object('Project', {id: projectId}))
            .notEqualTo("deleted", true)
            .ascending("name")
            .find()
    };
}

export function newRole(projectId, values) {
    return {
        type: NEW_ROLE,
        meta: {
            projectId
        },
        payload: () => {
            let role = new Parse.Object("ProjectRole")
                .set("project", new Parse.Object('Project', {id: projectId}));
            for (let key in values) {
                role.set(key, values[key]);
            }
            return role.save()
        }
    };
}

export function editRole(projectId, role) {
    return {
        type: EDIT_ROLE,
        meta: {
            projectId
        },
        payload: role
            .save()
    };
}