import React from 'react';

export const MapLegend = (props) => {
    let descriptions = [];
    return <div>
        {props.polys.map((poly, i) => {
            if (descriptions.indexOf(poly.description) !== -1) {
                return null;
            } else {
                descriptions.push(poly.description);
                return <MapLegendPart key={i} {...poly}/>
            }
        })
        }
    </div>;
};


function convertHex(hex, a){
    if (!hex) return '';
    hex = hex.replace('#','');
    let r = parseInt(hex.substring(0,2), 16);
    let g = parseInt(hex.substring(2,4), 16);
    let b = parseInt(hex.substring(4,6), 16);
    // let a = ('' + parseInt(hex.substring(6,8), 16)/255).substr(0,4);

    return 'rgba('+r+','+g+','+b+','+a+')';
}

export const MapLegendPart = (poly) => {
    let strokeWeight = (poly.strokeWidth ? poly.strokeWidth : 1);

    let borderColor = poly.strokeColor;
    let backgroundColor = poly.fillColor;
    let style = {};
    if (poly.isPolygon) {
        style = {
            border: strokeWeight + "px solid " + convertHex(borderColor, poly.strokeOpacity),
            backgroundColor: convertHex(backgroundColor, poly.fillOpacity),
            height: "16px",
            margin: "2px"
        }
    } else {
        style = {
            backgroundColor: convertHex(borderColor, poly.strokeOpacity),
            height: "4px",
            margin: "8px 0"
        }
    }
    // console.log(style);
    return (
        <div style={{float: "left", width: "80px", marginRight: "10px", color: "#555", fontSize: "80%", textAlign: "center"}}>
            <div style={style} />
            {poly.description}
        </div>
    )
};