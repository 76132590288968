import React from 'react';
import ReactClass from 'create-react-class';
import Parse from 'parse';
import UserRow from '../userRow';
import moment from "moment";
import {Glyphicon} from "react-bootstrap";

export default ReactClass({

    getInitialState() {
        return {
            users: [],
            memberships: {},
            passwordResets: [],
            showModal: false,
            sort: {
                on: "email",
                ascending: true,
            }
        };
    },

    fetchUserList() {

        let _this = this;

        let Membership = Parse.Object.extend("Membership");
        let sessionsQuery = new Parse.Query(Membership);
        sessionsQuery.include("project.name");
        sessionsQuery.limit(10000);
        sessionsQuery.find().then((results) => {
            let memberships = {};
            results.forEach((membership, i) => {
                let id = membership.get("user").id;
                if (!memberships[id])memberships[id] = [];
                memberships[id].push(membership);
            });

            _this.setState({
                memberships,
            });
        });

        let User = Parse.Object.extend("User");
        let query = new Parse.Query(User);
        query.ascending("username");
        query.include("userInfo");
        query.limit(10000);
        query.find().then((results) => {

            let users = [];
            results.forEach((user, i) => {
                // console.log(user.get("userInfo").get("name"))
                let userInfo = user.get("userInfo");
                users.push({
                    object: user,
                    id: user.id,
                    email: user.get("username"),
                    createdAt: user.get("createdAt"),
                    name: userInfo ? userInfo.get("name") : "",
                });
            });

            users.sort((a, b) => a["email"].toLowerCase().localeCompare(b["email"].toLowerCase()))

            _this.setState({
                users,
            });

        });

    },

    fetchPasswordRequestLinks() {

        let _this = this;
        let PasswordReset = Parse.Object.extend("PasswordReset");
        let query = new Parse.Query(PasswordReset);
        query.limit(1);
        query.descending("createdAt") //trackingNumber
        let usersQuery = query.find();

        usersQuery.then((results) => {

            let passwordResets = [];
            results.forEach((user, i) => {
                passwordResets.push({
                    object: user,
                    id: user.id,
                });
            });

            _this.setState({
                passwordResets,
            });

        });

    },

    passwordResetSuccess() {
        console.log("success")

        window.setTimeout(() => window.location.reload(), 1000);

        // document.body.scrollTop = document.documentElement.scrollTop = 0;
        // this.fetchPasswordRequestLinks();
    },

    newUser() {
        this.setState({showModal: true});
    },

    closeModal(user) {
        if (user !== undefined && user.id !== undefined) {
            let users = this.state.users || [];
            let userObject = {object: user, id: user.id};
            users.push(userObject);
            this.setState({users: users, showModal: false});
        } else {
            this.setState({showModal: false});
        }
    },

    componentDidMount() {
        this.fetchUserList();
        this.fetchPasswordRequestLinks();
    },

    toggleSort(column) {
        let sort = this.state.sort;
        if (sort.on === column) {
            sort.ascending = !sort.ascending;
        } else {
            sort.on = column;
            sort.ascending = true;
        }
        this.setState({sort});

        let users = [...this.state.users];
        if (sort.on === "createdAt") {
            users.sort((a, b) => a[sort.on] - b[sort.on])
        } else if (sort.on === "projects") {
            const memberships = this.state.memberships;
            users.sort((a, b) => (memberships[a.id] && memberships[a.id].length || 0) -
                (memberships[b.id] && memberships[b.id].length || 0))
        } else {
            users.sort((a, b) => a[sort.on].toLowerCase().localeCompare(b[sort.on].toLowerCase()))
        }

        if (!sort.ascending)users.reverse();

        this.setState({
            users,
        });

    },

    render() {
        return (
            <div className="card">
                <div className="card-heading">
                    <h2>
                        Users
                        {/*<Button bsStyle="primary" className="pull-right" onClick={this.newUser}>*/}
                            {/*<Glyphicon glyph="plus"/>&nbsp;*/}
                            {/*New user*/}
                        {/*</Button>*/}
                    </h2>
                </div>
                    <h4>Last password reset link created:</h4>
                {
                    this.state.passwordResets.length > 0 ? (
                        <table className="table table-striped table-condensed">
                            <thead>
                            <tr>
                                <th style={{width: '180px'}}>Date</th>
                                <th>E-mail</th>
                                <th>Reset password link</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.passwordResets.map((user) => user && <tr key={user.id}>
                                <td>{moment(user.object.get("createdAt")).format("DD-MM-YYYY HH:mm")}</td>
                                <td><a href={"mailto:" + (user.object.get("email"))}>{user.object.get("email")}</a></td>
                                <td><a href={user.object.get("link")} target="_blank">{user.object.get("link")}</a></td>
                                {/*<td>*/}
                                {/*    <Button href={"mailto:" + user.object.get("email") + "?body=" + encodeURIComponent(user.object.get("html"))}>Send e-mail</Button>*/}
                                {/*</td>*/}
                            </tr>)}
                            </tbody>
                        </table>
                    ) : (
                        <div className="card-body">
                            <em>No password reset links found.<br /><br /></em>
                        </div>
                    )
                }
                <hr />
                {
                    this.state.users.length > 0 ? (
                        <table className="table table-striped table-condensed">
                            <thead>
                            <tr>
                                <th onClick={() => this.toggleSort("name")} className={"clickable"}>
                                    Name
                                    {this.state.sort.on === "name" && <div className={"pull-right"}>
                                        <Glyphicon glyph={this.state.sort.ascending ? "triangle-top" : "triangle-bottom"}/>
                                    </div>}
                                </th>
                                <th onClick={() => this.toggleSort("email")} className={"clickable"}>
                                    E-mail
                                    {this.state.sort.on === "email" && <div className={"pull-right"}>
                                        <Glyphicon glyph={this.state.sort.ascending ? "triangle-top" : "triangle-bottom"}/>
                                    </div>}
                                </th>
                                <th style={{width: '180px'}} onClick={() => this.toggleSort("createdAt")} className={"clickable"}>
                                    Created at
                                    {this.state.sort.on === "createdAt" && <div className={"pull-right"}>
                                        <Glyphicon glyph={this.state.sort.ascending ? "triangle-top" : "triangle-bottom"}/>
                                    </div>}
                                </th>
                                <th style={{width: '110px'}} onClick={() => this.toggleSort("projects")} className={"clickable"}>
                                    Projects
                                    {this.state.sort.on === "projects" && <div className={"pull-right"}>
                                        <Glyphicon glyph={this.state.sort.ascending ? "triangle-top" : "triangle-bottom"}/>
                                    </div>}
                                </th>
                                <th>Reset password</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.users.map((user) => {
                                return (
                                    <UserRow key={user.id} user={user} memberships={this.state.memberships[user.id]} passwordResetSuccess={() => this.passwordResetSuccess()}/>
                                );
                            })}
                            </tbody>
                        </table>
                    ) : (
                        <div className="card-body">
                            <em>No users found.<br /><br /></em>
                        </div>
                    )
                }
                {/*<ModalUser show={this.state.showModal} close={this.closeModal}/>*/}
            </div>
        );
    },

});