import React from 'react';
import ReactClass from 'create-react-class';
import {Button, Modal, Form, Alert, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import spinnerImage from "../resources/images/spinner.gif";
import Parse from "parse";
import crypto from "crypto";
import {connect} from "react-redux";
import * as Action from "../actions/projects";

let SharePointModal = ReactClass({

    getInitialState() {
        return {
            loading: false,
            link: "",
            message: "",
            project: "",
        };
    },

    save(event) {
        event.preventDefault();

        const {link, project} = this.state;
        if (link === "" || project === "") {
            this.setState({message: "Select a project and enter a SharePoint list URL"})
            return;
        }

        this.setState({
            loading: true,
            message: "",
        });
        let _this = this;

        fetch('https://sharepoint.hseq.webzites.link/parse-url',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({link})
            }
        ).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.message) {
                    _this.setState({
                        message: data.message,
                        loading: false,
                    });
                } else {
                    const SharePointLink = Parse.Object.extend("SharePointLink");
                    let sharepointLink = new SharePointLink();
                    sharepointLink.set("listId", data.listId);
                    sharepointLink.set("siteId", data.siteId);
                    sharepointLink.set("sharepointURL", link);
                    sharepointLink.set("active", true);
                    sharepointLink.set("project", new Parse.Object("Project", {id: project}));
                    return sharepointLink.save();
                }
            }).then(() => {
            _this.setState({
                loading: false,
                project: "",
                link: "",
            });
            this.props.onHide();
        }).catch(e => {
            console.error(e);
        });

        return false;
    },

    render() {
        const {show, onHide, projects} = this.props;
        let items = projects && projects.items || [];
        let orderedProjects = Object.values(items).sort((a, b) => a.get("name").localeCompare(b.get("name")));

        return (
            <Modal show={show} onHide={onHide}>
                <Form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add SharePoint link</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.message &&
                            <Alert bsStyle="danger">
                                {this.state.message}
                            </Alert>}
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Project</ControlLabel>
                            <FormControl componentClass="select" onChange={(e) => this.setState({project: e.target.value})}>
                                <option value="">Select project</option>
                                {orderedProjects && orderedProjects.map(p => <option key={"pi" + p.id} value={p.id}>
                                    {p.get("name")}
                                </option>)}
                            </FormControl>
                        </FormGroup>
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Link</ControlLabel>
                            <FormControl
                                type="text"
                                name="name"
                                value={this.state.link}
                                placeholder="https://ventolines204393.sharepoint.com/sites/"
                                onChange={(e) => this.setState({link: e.target.value})}
                            />
                        </FormGroup>
                    </Modal.Body>


                    <Modal.Footer>
                        <Button onClick={onHide}>Cancel</Button>
                        {this.state.loading ? (
                            <img src={spinnerImage} alt="Loading..."/>
                        ) : (
                            <Button bsStyle="primary" type="submit">Submit</Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    },

});

// export default SharePointModal;

export default connect(state => ({
    projects: state.projects
}), dispatch => ({
}))(SharePointModal);
