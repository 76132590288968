import Parse from 'parse';
import {PRIORITY, PRIORITY_COLOR} from "../constants";

class HSERegistration extends Parse.Object {

    constructor() {
        super('HSERegistration');
        // this.editing = false
    }
    getTypeDescription() {
        let type = this.get("registrationCategory") && this.get("registrationCategory").get("name");
        if(!type) type = this.get("type") && this.get("type").get("name");
        return type;
    }
    getActivityDescription(defaultValue) {
        return this.get("registrationActivity") && this.get("registrationActivity").get("name") || defaultValue;
    }
    getDangerDescription(defaultValue) {
        return this.get("registrationDanger") && this.get("registrationDanger").get("name") || defaultValue;
    }
    getWorkpackageDescription(defaultValue) {
        return this.get("workpackage") && this.get("workpackage").get("name") || defaultValue;
    }
    getWindparkDescription(defaultValue) {
        return this.get("windpark") && this.get("windpark").get("name") || defaultValue;
    }

    getPriorityColor() {
        if(this.get("registrationPriority")){
            return this.get("registrationPriority").get("color")
        }
        return PRIORITY_COLOR[this.get("priority")] || "grey"
    }
    getStyledTrackingNumber() {
        let trackingNumberFormatted = this.get("trackingNumberFormatted");
        // if(!trackingNumberFormatted || trackingNumberFormatted === "") {
        //     trackingNumberFormatted = "#S" + ("00000" + this.get("trackingNumber")).substr(-5);
        // }
        return trackingNumberFormatted;
    }
    getPriorityString() {
        if(this.get("registrationPriority")){
            return this.get("registrationPriority").get("name")
        }
        return PRIORITY[this.get("priority")] || "Unknown"
    }
    getThumbnailUrl() {
        return this.get("thumbnail") ? this.get("thumbnail").url() : "";
    }
    getCallback() {
        return (this.get("callback") || 0) === 1
    }
    getShortDescription() {
        const description = this.get("description");
        let shortDescription = description && description.substr(0, 36);
        return (shortDescription || "") + (shortDescription !== description ? "..." : "");
    }
    // set(label, value) {
    //     console.log("EDITED SOMETHING!", this.editing);
    //     super.set(label, value);
    //     this.editing = true;
    //     console.log("EDITED SOMETHING!", this.editing);
    // }

}

export default HSERegistration;

Parse.Object.registerSubclass('HSERegistration', HSERegistration);