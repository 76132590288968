import Parse from 'parse';

export const FETCH_MAP_OVERLAY_POLYS = "FETCH_MAP_OVERLAY_POLYS";
export function fetchMapOverlayPolys(projectId){
    return {
        type: FETCH_MAP_OVERLAY_POLYS,
        meta: {
            projectId
        },
        payload: new Parse.Query("MapOverlayPoly")
            .equalTo("project", new Parse.Object('Project', { id: projectId }))
            .equalTo("active", true)
            .descending("isPolygon")
            .limit(10000)
            .find()
    };
}

export const FETCH_MAP_OVERLAY_POINTS = "FETCH_MAP_OVERLAY_POINTS";
export function fetchMapOverlayPoints(projectId){
    return {
        type: FETCH_MAP_OVERLAY_POINTS,
        meta: {
            projectId
        },
        payload: new Parse.Query("MapOverlayPoint")
            .equalTo("project", new Parse.Object('Project', { id: projectId }))
            .equalTo("active", true)
            .include("type")
            .find()
        // .then(objects => objects.reduce((o, n) => {o[n.id] = n; return o;}, {}))
    };
}

export const FETCH_POINT_TYPES = "FETCH_POINT_TYPES";
export function fetchPointTypes(){
    return {
        type: FETCH_POINT_TYPES,
        payload: new Parse.Query("MapOverlayPointType")
            .ascending("name")
            .find()
            .then(objects => objects.reduce((o, n) => {
                o[n.id] = n;
                return o;
            }, {}))
    };
}

export const SAVE_POINT = "SAVE_POINT";
export function savePoint(point, isNewPoint) {
    return {
        type: SAVE_POINT,
        meta: {
            isNewPoint,
            id: point.id,
            projectId: point.get("project").id,
        },
        payload: point.save()
    }
}

export const REMOVE_POINT = "REMOVE_POINT";
export function removePointById(pointId) {
    let point = new Parse.Object('MapOverlayPoint', { id: pointId });
    return {
        type: REMOVE_POINT,
        payload: point.destroy()
    }
}