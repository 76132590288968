import {createStore, applyMiddleware, combineReducers} from 'redux';

import promise from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import * as reducers from './reducers'

// console.log(reducers);

const middleware = applyMiddleware(
    promise,
    thunkMiddleware,
    logger
);

export default createStore(combineReducers(reducers), middleware);