import React from 'react';
import Parse from 'parse';
import {Link, NavLink, Redirect} from 'react-router-dom'
import {Form, FormGroup, FormControl, Col, ControlLabel, Button, Alert} from 'react-bootstrap';
import Auth from './auth.js'
import {connect} from 'react-redux';
import * as UserAction from './actions/users'

class SignUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            alert: "",
            message: "",
            resetDone: false,
        };

        // this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.resetPassword = this.resetPassword.bind(this);


    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleAlertDismiss() {
        this.setState({alert: ""});
    }

    handleSubmit(event) {
        event.preventDefault();
        let _this = this;
        Auth.authenticate(this.state.email, this.state.password).then((result) => {
            _this.forceUpdate();
            this.setState({email: ""});
            this.props.fetchCurrentUserInfo(Parse.User.current());
            if (Parse.User.current())window.location.reload();
        }).catch((error) => {
            _this.setState({
                alert: error.message + (this.state.email.toLowerCase() !== this.state.email ? " Try to enter your email address in lowercase." : "")
            });
        });
        this.setState({password: ""});
    }


    resetPassword() {
        let _this = this;

        _this.setState({message: "If an account with this e-mail address exists, it's password has been reset. Please click on the link in the e-mail to set a new password.", alert: ""});

        Parse.User.requestPasswordReset(this.state.email);
    }

    render() {

        if (Auth.isAuthenticated) {
            return (
                <Redirect to="/"/>
            )

        }
        return (
            <div className="card">
                <div className="card-body">
                    <h2>
                        Sign in
                    </h2>
                    {this.state.alert !== "" &&
                    <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                        <h4>Error</h4>
                        <p>{this.state.alert}</p><br/>
                        <Button onClick={this.resetPassword}>Reset password</Button>
                    </Alert>
                    }
                    {this.state.message !== "" &&
                    <Alert onDismiss={this.handleAlertDismiss}>
                        <p>{this.state.message}</p>
                    </Alert>
                    }
                    <Form horizontal onSubmit={this.handleSubmit}>
                        <FormGroup controlId="formHorizontalEmail">
                            <Col componentClass={ControlLabel} sm={2}>
                                Email
                            </Col>
                            <Col sm={10}>
                                <FormControl type="email" name="email" placeholder="Email" value={this.state.email}
                                             onChange={this.handleChange.bind(this)}/>
                            </Col>
                        </FormGroup>

                        <FormGroup controlId="formHorizontalPassword">
                            <Col componentClass={ControlLabel} sm={2}>
                                Password
                            </Col>
                            <Col sm={10}>
                                <FormControl type="password" name="password" placeholder="Password"
                                             value={this.state.password}
                                             onChange={this.handleChange.bind(this)}/>
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col smOffset={2} sm={8}>
                                <Button type="submit" className="button button-primary">
                                    Sign in
                                </Button>
                            </Col>
                            <Col style={{textAlign: 'right', paddingRight: '20px'}}>
                                <Link to="/reset-password">Forgot password?</Link>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        );
    }

};

export default connect(state => ({}), dispatch => ({
    fetchCurrentUserInfo: user => dispatch(UserAction.fetchCurrentUserInfo(user)),
    setActiveUser: user => dispatch(UserAction.setActiveUser(user)),
}))(SignUp)