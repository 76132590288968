import React from 'react'
import {connect} from "react-redux";
import * as ProjectActions from "../actions/projects";
import * as Action from "../actions/registrationTypes";


class ProjectSettings extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchTypes();
        this.props.fetchSettings();
    }

    updateActivitySetting(e, type, activity) {
        let on = e.target.checked;
        this.props.setTypeActivity(type, activity, on);
    }
    updateDangerSetting(e, type, danger) {
        let on = e.target.checked;
        this.props.setTypeDanger(type, danger, on);
    }

    render() {

        const {settings, typeObjects, typesFetching} = this.props;
        const activities = settings.activities || [];
        const dangers = settings.dangers || [];

        const types = Object.values(typeObjects);

        return (
            <div>
                <h4 style={{marginBottom: 0}}>Activities</h4>
                <table className="table table-striped ">
                    <thead>
                    <tr>
                        <th/>
                        {types.map(type => <th key={"type" + type.id}>{type.get("name")}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {activities.map(a => <tr key={"activity" + a.id}>
                        <th>
                            {a.get("name")}
                        </th>
                        {types.map(type => <td key={"cell" + type.id + a.id}>
                            <label>
                                <input type="checkbox"
                                       disabled={typesFetching}
                                       onChange={(e) => this.updateActivitySetting(e, type, a)}
                                       checked={(type.get("registrationActivities") || []).indexOf(a.id) !== -1}/>
                            </label>
                        </td>)}
                    </tr>)}
                    </tbody>
                </table>
                <h4 style={{marginBottom: 0}}>Danger</h4>
                <table className="table table-striped ">
                    <thead>
                    <tr>
                        <th/>
                        {types.map(type => <th key={"type" + type.id}>{type.get("name")}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {dangers.map(d => <tr key={"danger" + d.id}>
                        <th>
                            {d.get("name")}
                        </th>
                        {types.map(type => <td key={"celld" + type.id + d.id}>
                            <label>
                                <input type="checkbox"
                                       disabled={typesFetching}
                                       onChange={(e) => this.updateDangerSetting(e, type, d)}
                                       checked={(type.get("registrationDangers") || []).indexOf(d.id) !== -1}/>
                            </label>
                        </td>)}
                    </tr>)}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(state => ({
    typeObjects: state.registrationTypes.items || {},
    typesFetching: state.registrationTypes.fetching || false,
    settings: state.projects.settings,
}), dispatch => ({
    fetchSettings: () => dispatch(ProjectActions.fetchProjectSettings()),
    fetchTypes: () => dispatch(Action.fetch()),
    setTypeActivity: (a, b, c) => dispatch(Action.setTypeActivity(a, b, c)),
    setTypeDanger: (a, b, c) => dispatch(Action.setTypeDanger(a, b, c)),
}))(ProjectSettings);