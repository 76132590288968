import React from 'react';
import {connect} from "react-redux";


class WindparkSelector extends React.Component {

    handleChange(event) {
    }

    render() {
        const {editing, registration,  role, bypassRole} = this.props;
        let activeId = registration.get("windpark") && registration.get("windpark").id;

        return editing ? <>
            {/*<select value={activeId || ""} onChange={(e) => {this.handleChange(e)}}>*/}
            {/*    <option>None</option>*/}
            {/*    {windparks.filter(a => bypassRole || allowedWindparks.indexOf(a.id) !== -1).map((type, index) => {*/}
            {/*        return (*/}
            {/*            <option key={"act" + index} value={type.id}>*/}
            {/*                {type.get("name")}*/}
            {/*            </option>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</select>*/}
        </> : (
            <div>
                {registration.get("mapPoint") && registration.get("mapPoint").get("description")}
            </div>
        )

    }
}

export default connect(state => ({
    // windparks: Object.values(state.projects.settings.windparks || {})
    //     .filter(
    //     (d) => !d.get("deleted") && d.get("project") && d.get("project").id === state.projects.active
    // ),
    // role: state.projects.active && state.projects.items[state.projects.active]
    //     && state.projects.items[state.projects.active].role,
}), dispatch => ({
}))(WindparkSelector);