import React from 'react';
import {Button, Glyphicon,} from 'react-bootstrap';
import {connect} from "react-redux";
import * as UserAction from "./actions/users";

class MemberRow extends React.Component {

    /*
    0: normal user
    1: responsible person, able to see all registrations [role=hsemanager-XXX]
    2: administrator, able to see all registrations & change users (former hsemanager) [roles=hsemanager-XXX & admin-XXX]

     */

    types = ["User", "Responsible Person", "Administrator"];

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            editingType: false,
            editingNotifications: false,
            editingWorkpackages: false,
            editingRoles: false,
            notificationCategoryIds: [],
            workpackageIds: [],
            role: null,
        };
        this.remove = this.remove.bind(this);
        this.changeType = this.changeType.bind(this);
        this.changeNotifications = this.changeNotifications.bind(this);
        this.saveNotifications = this.saveNotifications.bind(this);
        this.changeWorkpackages = this.changeWorkpackages.bind(this);
        this.changeRoles = this.changeRoles.bind(this);
        this.saveRoles = this.saveRoles.bind(this);
        this.saveWorkpackages = this.saveWorkpackages.bind(this);
        this.updateType = this.updateType.bind(this);
    }

    remove() {
        if (window.confirm("Are you sure you want to remove this user from this project?")) {
            this.props.removeMembership(this.props.member);
        }
    }

    changeType() {
        this.setState({editingType: true});
    }

    changeNotifications() {
        let member = this.props.member;
        let notificationCategories = member.get("notificationCategories") || [];
        let notificationCategoryIds = notificationCategories.map(type => type.id);
        this.setState({
            editingNotifications: true,
            notificationCategoryIds,
        });
    }

    changeNotification(notificationId, checked) {
        let {notificationCategoryIds} = this.state;
        if (checked && notificationCategoryIds.indexOf(notificationId) === -1) {
            notificationCategoryIds.push(notificationId);
        } else if (!checked && notificationCategoryIds.indexOf(notificationId) !== -1) {
            notificationCategoryIds.splice(notificationCategoryIds.indexOf(notificationId), 1)
        }
        this.setState({
            editingNotifications: true,
            notificationCategoryIds,
        });
    }

    saveNotifications() {
        let {notificationCategoryIds} = this.state;
        this.setState({editingNotifications: false});
        let member = this.props.member;
        let notifications = this.props.types.filter(type => notificationCategoryIds.indexOf(type.id) !== -1);
        member.set("notificationCategories", notifications);
        member.save();
    }

    changeWorkpackages() {
        let member = this.props.member;
        let workpackages = member.get("workpackages") || [];
        let workpackageIds = workpackages.map(wp => wp.id);
        this.setState({
            editingWorkpackages: true,
            workpackageIds,
        });
    }

    changeRoles() {
        let member = this.props.member;
        this.setState({
            editingRoles: true,
            role: member.get("role")
        });
    }

    changeWorkpackage(workpackageId, checked) {
        let {workpackageIds} = this.state;
        if (checked && workpackageIds.indexOf(workpackageId) === -1) {
            workpackageIds.push(workpackageId);
        } else if (!checked && workpackageIds.indexOf(workpackageId) !== -1) {
            workpackageIds.splice(workpackageIds.indexOf(workpackageId), 1)
        }
        this.setState({
            editingWorkpackages: true,
            workpackageIds,
        });
    }

    saveRoles() {
        this.setState({editingRoles: false});
        let member = this.props.member;
        member.set("role", this.state.role || null);
        member.save();
    }

    saveWorkpackages() {
        let {workpackageIds} = this.state;
        this.setState({editingWorkpackages: false});
        let member = this.props.member;
        let workpackages = this.props.workpackages.filter(wp => workpackageIds.indexOf(wp.id) !== -1);
        member.set("workpackages", workpackages);
        member.save();
    }

    updateType(event) {
        let _this = this;
        this.setState({editingType: false});
        let member = this.props.member;
        let newType = parseInt(event.target.value, 10);
        member.set("type", newType)
            .save().then(() => {
            member = this.state.member || this.props.member;
            member.type = newType;
            member.typeDescription = this.types[newType];
            _this.setState({member: member});

        }).catch(error => {
            console.error(error);
        })
    }

    render() {
        let {member, user, types, workpackages, roles} = this.props;
        let {notificationCategoryIds, workpackageIds} = this.state;

        return (
            member && user ? (
                <tr>
                    <td>
                        <strong>{user.get("userInfo").get("name")}</strong><br/>
                        {user.get("username")}
                    </td>


                    {this.props.isAdmin && roles ?
                        (
                            this.state.editingRoles ? (
                                <td>
                                    <select
                                        value={this.state.role && this.state.role.id || ""}
                                        onChange={(e) => this.setState({role: e.target.value &&
                                                roles.items.filter(r => r.id === e.target.value)[0]})}>
                                        <option>No role</option>
                                        {roles.items.map((role, i) => <option key={role.id}
                                                                              value={role.id}>
                                            {role.get("name")}
                                        </option>)}
                                    </select>{" "}
                                    <Button bsStyle="primary" bsSize="xs" onClick={this.saveRoles}>Save</Button>
                                </td>
                            ) : (
                                <td className="clickable" onClick={this.changeRoles}>
                                    {member.get("role") ? member.get("role").get("name") : "No role"}
                                </td>
                            )
                        ) : <td></td>}

                    {this.props.isAdmin ?
                        this.state.editingType ? (
                            <td>
                                <select value={member.get("type")} onChange={this.updateType}>
                                    {this.types.map((description, type) => {
                                        return (
                                            <option value={type} key={type}>{description}</option>
                                        )
                                    })}
                                </select>
                            </td>
                        ) : (
                            <td className="clickable" style={{color: "#ccc"}} onClick={this.changeType}>{this.types[member.get("type")]}</td>
                        ) : <td>{this.types[member.get("type")]}</td>
                    }

                    {this.props.isAdmin && workpackages ?
                        (
                            // member.get("type") === 2 ? <td>All workpackages</td> :
                            this.state.editingWorkpackages ? (
                                <td>
                                    {workpackages.map((type, i) => <div key={type.id}>
                                        <label style={{fontWeight: "normal"}}>
                                            <input type="checkbox"
                                                   checked={workpackageIds.indexOf(type.id) !== -1}
                                                   onChange={(e) => this.changeWorkpackage(type.id, e.target.checked)}/> {type.get("name")}
                                        </label>
                                    </div>)}
                                    <Button bsStyle="primary" bsSize="xs" onClick={this.saveWorkpackages}>Save</Button>
                                </td>
                            ) : (
                                <td className="clickable" style={{color: "#ccc"}} onClick={this.changeWorkpackages}>
                                    {
                                        member.get("workpackages") && member.get("workpackages").length > 0 ? (
                                            member.get("workpackages").map(wp => wp.get("name")).join(", ")
                                        ) : (
                                            <span style={{color: "#aaa"}}>No workpackages</span>
                                        )
                                    }
                                </td>
                            )
                        ) : (<td/>)
                    }

                    {this.props.isAdmin && types && (member.get("type") === 1 || member.get("type") === 2) ?
                        (
                            this.state.editingNotifications ? (
                                <td>
                                    {types.map((type, i) => (
                                        <label style={{fontWeight: "normal"}} key={i}>
                                            <input type="checkbox"
                                                   checked={notificationCategoryIds.indexOf(type.id) !== -1}
                                                   onChange={(e) => this.changeNotification(type.id, e.target.checked)}/> {type.get("name")}
                                        </label>
                                    ))}<br/>
                                    <Button bsStyle="primary" bsSize="xs" onClick={this.saveNotifications}>Save</Button>
                                </td>
                            ) : (
                                <td className="clickable" onClick={this.changeNotifications}>
                                    {
                                        member.get("notificationCategories") && member.get("notificationCategories").length > 0 ? (
                                            member.get("notificationCategories").map(type => type.get("name")).join(", ")
                                        ) : (
                                            <span style={{color: "#aaa"}}>No notifications</span>
                                        )
                                    }
                                </td>
                            )
                        ) : (<td/>)
                    }

                    {this.props.isAdmin && (
                        <td><Button bsStyle="danger" onClick={this.remove}><Glyphicon glyph="remove"/></Button></td>
                    )}
                </tr>

            ) : (
                <tr>
                    <td colSpan="3">&nbsp;</td>
                </tr>
            )
        );
    }

}

export default connect(state => ({
        types: Object.values(state.registrationTypes.items),
        roles: state.roles.items[state.projects.active] || [],
        workpackages: state.workpackages.items[state.projects.active] &&
            state.workpackages.items[state.projects.active].items || [],
    }
), dispatch => ({
    removeMembership: (membership) => dispatch(UserAction.removeMembership(membership)),
}))(MemberRow);