import React from 'react';
import {connect} from "react-redux";
import {Button, Col, FormGroup, Row} from 'react-bootstrap';
import Parse from 'parse';
import * as ProjectAction from "../actions/projects";

class ProjectCoordinatesField extends React.Component {

    constructor(props) {
        super(props);
        let coordinates = props.project.get("coordinates");
        if (coordinates) {
            this.state = {
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            }
        } else {
            this.state = {
                lat: "",
                lng: "",
            }
        }
    }

    componentWillReceiveProps(props) {
        let coordinates = props.project.get("coordinates");
        if (coordinates) this.setState({
            lat: coordinates.latitude,
            lng: coordinates.longitude,
        })
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value.replace(/[^\d.]/g, "")});
    }

    save() {

        let project = this.props.project;
        let point = new Parse.GeoPoint({latitude: parseFloat(this.state.lat), longitude: parseFloat(this.state.lng)});
        project.set("coordinates", point);
        this.props.saveProject(project);

    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col xs={3}>
                        <strong>Project Coordinates</strong>
                    </Col>
                    <Col xs={6}>
                        <input className="input-sm" type="text" name="lat" value={this.state.lat}
                               onChange={this.handleChange.bind(this)}
                               placeholder="Latitude"/>
                        <input className="input-sm" type="text" name="lng" value={this.state.lng}
                               onChange={this.handleChange.bind(this)}
                               placeholder="Longitude"/>
                    </Col>
                    <Button bsStyle="primary" onClick={this.save.bind(this)} className="pull-right">Save</Button>
                </Row>
            </FormGroup>
        );
    }
}

export default connect(state => ({}), dispatch => ({
    saveProject: (project) => dispatch(ProjectAction.saveProject(project)),
}))(ProjectCoordinatesField);