import React from 'react';
import ReactClass from 'create-react-class';
import Parse from 'parse';
import {Button, FormGroup, FormControl, ControlLabel, Modal, Form, Alert} from 'react-bootstrap';

export default ReactClass({

    getInitialState() {
        return {
            name: "",
            showModal: false,
            alert: "",
        };
    },

    closeModal(project) {
        this.props.close(project)
    },

    handleAlertDismiss() {
        this.setState({alert: ""});
    },

    save(event) {
        event.preventDefault();
        let _this = this;
        let Project = Parse.Object.extend("Project");
        let project = new Project();
        project.set("name", this.state.name);
        project.set("googleMapsType", "hybrid");
        _this.setState({name: ""});
        project.save().then(() => {
            _this.closeModal(project);
        }).catch(error => {
            _this.setState({alert: error.message});
        });
        return false;
    },

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    },

    render() {
        return (
            <Modal show={this.props.show} onHide={this.closeModal}>
                <Form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.alert !== "" &&
                        <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                            <h4>Error</h4>
                            <p>{this.state.alert}</p>
                        </Alert>
                        }
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Project name</ControlLabel>
                            <FormControl
                                type="text"
                                name="name"
                                value={this.state.name}
                                placeholder="Example project"
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal}>Cancel</Button>
                        <Button bsStyle="primary" type="submit">Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    },

});