import * as Action from '../actions/projects'
import {PENDING, FULFILLED} from '../actions/constants';
import update from 'immutability-helper';

export function projects(state = {
    items: {},
    allItems: [],
    fetching: false,
    fetched: false,
    active: null,
    settings: {},
}, action) {
    switch (action.type) {
        case Action.FETCH_PROJECTS + PENDING:
            return Object.assign({}, state, {
                fetching: true
            });
        case Action.FETCH_PROJECTS + FULFILLED:
            return Object.assign({}, state, {
                fetching: false,
                fetched: true,
                items: action.payload,
            });
        case Action.FETCH_ALL_PROJECTS + FULFILLED:
            return Object.assign({}, state, {
                allItems: action.payload,
            });
        case Action.SET_ACTIVE_PROJECT:
            return Object.assign({}, state, {
                active: action.projectId
            });
        case Action.SAVE_PROJECT_RELOAD + FULFILLED:
            // window.location.reload();
            // return;
        case Action.SAVE_PROJECT + FULFILLED:
            return update(state, {
                items: {
                    [action.payload.id]:
                        {$merge: [action.payload]},
                }
            });
        case Action.COUNT_REGISTRATIONS + FULFILLED:
            return update(state, {
                items: {
                    [action.meta.projectId]:
                        {registrationCount: {$set: action.payload}},
                }
            });

        case Action.FETCH_PROJECT_SETTINGS:
            const {activities, dangers, windparks} = action.payload;
            return update(state, {
                settings: {
                    activities: {$set: activities},
                    dangers: {$set: dangers},
                    windparks: {$set: windparks},
                }
            });

        default:
            return state;
    }
};
