import React from 'react';
import Parse from 'parse';
import {Link, NavLink, Redirect} from 'react-router-dom'
import {Form, FormGroup, FormControl, Col, ControlLabel, Button, Alert} from 'react-bootstrap';
import Auth from './auth.js'
import {connect} from 'react-redux';
import * as UserAction from './actions/users'

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        let email = "";
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("email"))email = atob(searchParams.get("email"));

        this.state = {
            email,
            password: '',
            alert: "",
            message: "",
            resetDone: false,
        };

        // this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleAlertDismiss() {
        this.setState({alert: ""});
    }

    handleSubmit(event) {
        event.preventDefault();
        let _this = this;
        _this.setState({message: "If an account with this e-mail address exists, an email has been sent with a link to set a new password. Please check your spam folder if you can't find this email.", alert: ""});

        Parse.User.requestPasswordReset(this.state.email);
    }

    render() {

        if (Auth.isAuthenticated) {
            return (
                <Redirect to="/"/>
            )

        }
        return (
            <div className="card">
                <div className="card-body">
                    <h2>
                        {window.location.pathname.indexOf("reset") > -1 ? "Reset " : "Set "}
                        password
                    </h2>
                    {this.state.message !== "" &&
                    <Alert onDismiss={this.handleAlertDismiss}>
                        <p>{this.state.message}</p>
                    </Alert>
                    }
                    <Form horizontal onSubmit={this.handleSubmit}>
                        <FormGroup controlId="formHorizontalEmail">
                            <Col componentClass={ControlLabel} sm={2}>
                                Email
                            </Col>
                            <Col sm={10}>
                                <FormControl type="email" name="email" placeholder="Email" value={this.state.email}
                                             onChange={this.handleChange.bind(this)}/>
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col smOffset={2} sm={8}>
                                <Button type="submit" className="button button-primary">
                                    Submit
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        );
    }

};

export default connect(state => ({}), dispatch => ({
    setActiveUser: user => dispatch(UserAction.setActiveUser(user))
}))(ResetPassword)