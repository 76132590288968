import Parse from 'parse'
import crypto from 'crypto';

export const SET_ACTIVE_USER = "SET_ACTIVE_USER";

export function setActiveUser(user) {
    return {
        type: SET_ACTIVE_USER,
        payload: user
    }
}

export function fetchCurrentUserInfo() {
    console.log("fetchCurrentUserInfo");
    if (!Parse.User.current()) return;
    return dispatch => {
        new Parse.Query(Parse.User)
            .equalTo("objectId", Parse.User.current().id)
            .include("userInfo")
            .first()
            .then(user => dispatch(setActiveUser(user)))
    }
}

export function updateCurrentUserInfo(newData) {
    return (dispatch, state) => {
        const {current} = state().users;
        current.get("userInfo").set(newData)
        current.get("userInfo").save()
            .then(user => dispatch(fetchCurrentUserInfo()))
    }
}

export const FETCH_USERS = "FETCH_USERS";

export function fetchUsers() {
    return (dispatch, getState) => {
        let state = getState();
        if (state.fetching !== true)
            dispatch({
                type: FETCH_USERS,
                payload: new Parse.Query(Parse.User)
                    .include("userInfo")
                    .find()
                    .then(objects => objects.reduce((o, n) => {
                        o[n.id] = n;
                        return o;
                    }, {}))
            })
    }
}

export const FETCH_MEMBERS = "FETCH_MEMBERS";

export function fetchMembersByProjectById(projectId) {

    return (dispatch, getState) => {
        let state = getState();
        if (state.users.memberships === undefined ||
            state.users.memberships[projectId] === undefined
            || !state.users.memberships[projectId].fetching)
            dispatch({
                type: FETCH_MEMBERS,
                meta: {projectId},
                payload: new Parse.Query("Membership")
                    .descending("createdAt")
                    .equalTo("project", new Parse.Object('Project', {id: projectId}))
                    .include("user")
                    .include("user.userInfo")
                    .include("notificationCategories.name")
                    .find()
                    .then(items => items.filter(a => a.get("user") !== undefined))
            })
    }
}

export const ADD_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";

export function addUserToProject(mail, project) {
    //Check for existing membership first?
    return (dispatch, getState) => {
        dispatch({
            type: ADD_USER_TO_PROJECT,
            meta: {mail},
            payload:
                new Parse.Query("User")
                    .include("userInfo")
                    .matches("username", "^" + mail + "$", "i") //Case insensitive
                    // .equalTo("username", mail)
                    .first()
                    .then(user => {
                        return new Parse.Query("Membership")
                            .equalTo("user", user)
                            .equalTo("project", project)
                            .count()
                            .then(count => {
                                return count === 0 ? user : {existing: true}
                            })
                    })
        })
    }
}

export const CANCEL_ADD_USER_TO_PROJECT = "CANCEL_ADD_USER_TO_PROJECT";

export function cancelAddUserToProject() {
    return {
        type: "CANCEL_ADD_USER_TO_PROJECT",
    }
}


export const REMOVE_MEMBERSHIP = "REMOVE_MEMBERSHIP";

export function removeMembership(membership) {
    return {
        type: "REMOVE_MEMBERSHIP",
        payload: membership.destroy()
    }
}

export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP";

export function addMembership(user, project, tryNr, newUserEmail) {
    return (dispatch) => {
        let Membership = Parse.Object.extend("Membership");
        let membership = new Membership();
        membership.set("user", user);
        membership.set("project", project);
        membership.set("type", 0);

        dispatch({
            type: "ADD_MEMBERSHIP",
            payload: membership.save(),
            meta: {newUserEmail}
        }).catch(e => {
            if (e && e.code === 101 && (!tryNr || tryNr < 3)) {
                setTimeout(() =>
                        dispatch(addMembership(user, project, tryNr ? tryNr + 1 : 1, newUserEmail)),
                    300);
            } else if (e && e.code === 101) {
                alert("Something went wrong... Please reload the page and add the user again.");
            }
        });
    };
}

export const ADD_USER = "ADD_USER";

export function addUser(mail, name, phone, project, sendEmail) {
    let UserInfo = Parse.Object.extend("UserInfo");
    let userInfo = new UserInfo();
    userInfo.set("name", name);
    userInfo.set("phonenumber", phone);
    return (dispatch) => {
        dispatch({
            type: ADD_USER,
            payload:
                userInfo.save().then(() => {
                    let user = new Parse.User();
                    let username = mail;
                    user.set("username", username);
                    user.set("email", username);
                    user.set("password", crypto.randomBytes(12).toString('base64'));
                    user.set("userInfo", userInfo);
                    return user.save().then(() => {
                        userInfo.set("user", user);
                        userInfo.save();
                        dispatch(addMembership(user, project, undefined, username));
                        if (sendEmail) {
                            dispatch({type: "REQUEST_PWD_RESET", payload: Parse.User.requestPasswordReset(username)});
                        }
                    })
                })
        })
    }
}

