import React from 'react';
import RegistrationRow from '../registrationRow';
import RegistrationPage from '../registrationPage';
import {connect} from 'react-redux';
import * as RegistrationAction from '../actions/registrations'
import * as ProjectAction from '../actions/projects'
import {Button} from "react-bootstrap";
import * as ReactDom from "react-dom";
import spinnerImage from '../resources/images/spinner.gif';
import TypeFilter from "../components/TypeFilter";
import LinkContainer from "../components/LinkContainer";
import * as WorkpackagesAction from "../actions/workpackages";
import SearchBar from "../components/SearchBar";

class ProjectPage extends React.Component {

    projectId;
    registrationId;

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.loadData();

    }

    componentDidUpdate() {
        this.loadData();
    }

    loadData() {

        console.log(`loadData project.js, 
        ${this.projectId} / ${this.registrationId}
        `);

        // Check if project or registration is changed


        // this.props.fetchCurrentUserInfo();
        this.props.fetchProjectsIfNeeded();
        this.props.fetchProjectSettings();

        const {registrations, activeRegistration} = this.props;

        let projectId = this.props.match.params.id;
        if (this.projectId !== projectId) {
            this.projectId = projectId;
            this.props.setActiveProjectById(projectId);
            this.props.fetchWorkpackages(projectId);

            // if (!registrations.fetched) { // activeProject &&
                this.props.fetchRegistrationsIfNeeded(projectId);
            // }
        }

        let registrationId = this.props.match.params.registration_id;
        // if (this.registrationId !== registrationId) {
            this.registrationId = registrationId;
            if (registrations.fetched && registrationId) {
                if (!activeRegistration ||
                    (activeRegistration.id && activeRegistration.id !== registrationId)
                ) {
                    this.props.setActiveRegistration(registrationId)
                }
            }
        // }
    }

    goToPage(page) {
        this.props.setPage(page - 1);
        ReactDom.findDOMNode(this).scrollIntoView();

    }

    render() {

        const project = this.props.activeProject;

        const map = this.props.activeProjectMap;
        const {registrations, activeRegistration} = this.props;
        const {pagination} = registrations;

        let items = Object.values(registrations.items);

        let page;
        let numPages;
        let to = 1;
        let from = pagination.skip + 1;
        let registrationCount = 0;
        if (project) {
            registrationCount = project.registrationCount || 0;
            page = pagination.skip / pagination.limit + 1;
            numPages = Math.ceil(registrationCount / pagination.limit);
            to = Math.min(registrationCount, from + pagination.limit - 1);
        }
        //j.bijker: f2F7sHmtvB

        return (
            <div className="card">
                <div className="card-heading">
                    {project && (
                        <h2>

                            {project.get("icon") != null &&
                                <img src={project.get("icon").url()} style={{width: 100, marginRight: 10}}/>
                            }

                            Project&nbsp;
                            <small>{project.get("name")}</small>
                            {project.isAdmin &&
                                <LinkContainer to={"/edit-project/" + project.id} className="pull-right">
                                    <Button className="pull-right">manage project</Button>
                                </LinkContainer>
                            }

                            <LinkContainer to={"/project/" + project.id + "/registration/new"}>
                                <Button bsStyle="primary"
                                        style={{marginRight: 5}}
                                        className="pull-right">new registration</Button>
                            </LinkContainer>
                        </h2>
                    )}
                </div>
                <div className="negative-margin">
                    <div className="col-sm-5 col-sm-push-7" style={{position: "relative"}}>
                        {activeRegistration ? (
                            <RegistrationPage registration={activeRegistration} project={project} map={map}/>
                        ) : <>&nbsp;</>}
                    </div>
                    <div className="col-sm-7 col-sm-pull-5">
                        {registrations && items.length > 0 ? (
                            <div>
                                <div className="toolbar">
                                    <div className="light">
                                        <TypeFilter style={{float: "right"}} project={project}/>
                                        <span>Showing {from} to {to} from {registrationCount} reports</span>
                                    </div>
                                    <div className="search">
                                        <SearchBar project={project} />
                                    </div>
                                </div>
                                {registrations.fetching ? (
                                    <div style={{textAlign: "center"}}><img src={spinnerImage} alt="Loading..."/></div>
                                ) :
                                <table className="table table-striped">
                                    <tbody>
                                    {items.map((registration, id) => (
                                        <RegistrationRow key={"registrationRow" + id} registration={registration}
                                                         project={project}
                                                         activeRegistration={registrations.active && registrations.active.item}/>
                                    ))}
                                    </tbody>
                                </table>}
                                {project && numPages > 1 && (
                                    <div className="bottombar light-blue-background">
                                        <Button disabled={page <= 1} className="pull-left"
                                                onClick={this.goToPage.bind(this, page - 1)}>previous</Button>
                                        <Button disabled={page === numPages} className="pull-right"
                                                onClick={this.goToPage.bind(this, page + 1)}>next</Button>
                                        <span className="light">page</span> {page} <span
                                        className="light">/</span> {numPages}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className="toolbar">
                                    <div className="light">
                                        <TypeFilter style={{float: "right"}} project={project}/>
                                    </div>
                                </div>
                                {registrations.fetching ? (
                                    <div style={{textAlign: "center"}}><img src={spinnerImage} alt="Loading..."/></div>
                                ) : (
                                    <div className="card-body">No registrations added yet.<br/><br/></div>
                                )}
                            </div>
                        )
                        }

                    </div>
                </div>
                <div className="clearfix"/>
            </div>
        );
    }

}

export default connect(state => ({
    projects: state.projects,
    registrations: state.registrations,
    activeProject: state.projects.items[state.projects.active],
    activeRegistration: state.registrations.active.item,
    activeProjectMap: state.maps.items[state.projects.active],
}), dispatch => ({
    // fetchCurrentUserInfo: () => dispatch(UserAction.fetchCurrentUserInfo()),
    fetchWorkpackages: projectId => dispatch(WorkpackagesAction.fetch(projectId)),
    fetchProjectSettings: () => dispatch(ProjectAction.fetchProjectSettings()),
    fetchProjectsIfNeeded: () => dispatch(ProjectAction.fetchProjectsIfNeeded()),
    fetchRegistrationsIfNeeded: project => dispatch(RegistrationAction.fetchRegistrationsIfNeeded(project)),
    setActiveProjectById: project => dispatch(ProjectAction.setActiveProjectById(project)),
    setActiveRegistration: project => dispatch(RegistrationAction.setActiveRegistration(project)),
    setPage: page => dispatch(RegistrationAction.setActivePage(page))
}))(ProjectPage);