import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import * as RegistrationAction from "./actions/registrations";
import {Glyphicon, Label, OverlayTrigger, Tooltip} from "react-bootstrap";
import LinkContainer from "./components/LinkContainer";
import icon_open from "./resources/images/np_checkbox-unchecked.png"
import icon_closed from "./resources/images/np_checkbox.png"

class Project extends React.Component {

    render() {

        const {registration, project, activeRegistration} = this.props;

        const tooltip = (
            <Tooltip id="tooltip">
                <strong>Warning:</strong> This report is edited but has not been saved.
            </Tooltip>
        );

        return (
            registration === undefined ? (
                <tr>
                    <td colSpan="7">&nbsp;</td>
                </tr>
            ) : (
                <LinkContainer className="clickable" to={"/project/" + project.id + "/registration/" + registration.id}>
                    <tr className={"clickable " + (activeRegistration && activeRegistration.id === registration.id ? "active" : "")}>
                        <td style={{padding:0,paddingLeft:10,width:40}}>
                            <img src={registration.get("open") ? icon_open : icon_closed} className="checkbox" />
                        </td>
                        <td style={{width:90,paddingLeft:0}} className="registration-thumbnail-container">
                            <div className="registration-thumbnail" style={{backgroundImage: "url('" + registration.getThumbnailUrl() + "')"}}>
                                {/*<div>{registration.get("imageCount") > 0 ? registration.get("imageCount") : "no"} image{registration.get("imageCount") === 1 ? "" : "s"}</div>*/}
                            </div>
                        </td>
                        <td>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="priority-circle" style={{backgroundColor:registration.getPriorityColor()}} />
                                    <strong>{registration.getTypeDescription()}</strong><br />
                                    <span className="registration-number">{registration.getStyledTrackingNumber()}</span><br />
                                    {registration.get("workpackage") &&
                                        <Label>{registration.get("workpackage").get("name")}</Label>
                                    }{" "}
                                    {registration.get("windpark") &&
                                        <Label>
                                            <img src={"/resources/images/park.svg"} style={{height: 14}} />{" "}
                                            {registration.get("windpark").get("name")}
                                        </Label>
                                    }{" "}
                                    {registration.get("mapPoint") &&
                                        <Label>
                                            <img src={"/resources/images/turbine.svg"} style={{height: 14}} />{" "}
                                            {registration.get("mapPoint").get("description")}</Label>
                                    }
                                </div>
                                <div className="col-md-4" style={{textAlign: "right"}}>
                                    {registration.get("user").get("userInfo").get("name")}
                                    {registration.getCallback() && (
                                        <img src="/resources/images/callback.png" alt="" className="callback-image" />
                                    )}
                                    <br />
                                    <span className="registration-date">{moment(registration.get("createdAt")).format("D MMM YY, HH:mm")}</span><br />
                                    {registration.get("versionLogCount") && registration.get("versionLogCount") > 0 && (
                                        <span className="registration-edits">&nbsp;({registration.get("versionLogCount")} edit{registration.get("versionLogCount") === 1 ? "" : "s"})</span>
                                    )}
                                </div>
                            </div>


                            <div className="registration-description-summary">
                                {registration.getShortDescription()}

                                {registration.dirty() && (
                                    <OverlayTrigger placement="left" overlay={tooltip}>
                                        <Label bsStyle="danger" style={{marginLeft:10,float:"right"}}><Glyphicon glyph="exclamation-sign" /></Label>
                                    </OverlayTrigger>
                                )}
                            </div>

                        </td>
                        {/*<td>{registration.get("description")}</td>*/}
                        {/*<td>{registration.get("type") && registration.get("type").get("name")}</td>*/}
                        {/*<td><Label style={{backgroundColor:registration.getPriorityColor()}}>{registration.getPriorityString()}</Label></td>*/}
                        {/*<td>*/}
                        {/*<ImageGallery thumbnail={registration.getThumbnailUrl()} />*/}
                        {/*</td>*/}
                    </tr>
                </LinkContainer>
            )
        );
    }

}

export default connect(state => ({
    // registrations: state.registrations,
    // projects: state.projects,
}), dispatch => ({
    setActiveRegistration: project => dispatch(RegistrationAction.setActiveRegistration(project)),
}))(Project)