import React from 'react';

export default class DescriptionField extends React.Component {

    handleChange(event) {
        this.props.registration.set("description", event.target.value)
    }

    render() {
        const {editing, registration} = this.props;

        return (
            <div className="registration-description">
                <h4>Description</h4>

                {editing ? (
                    <textarea defaultValue={registration.get("description")} onChange={(e) => {this.handleChange(e)}} />
                ) : (
                    registration.get("description") || <em>No description provided</em>
                )}
            </div>
        )

    }
}