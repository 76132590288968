import React from 'react';
import Parse from 'parse';
import {connect} from 'react-redux';
import {Button, Glyphicon, Label,} from 'react-bootstrap';
import * as Action from '../actions/projects';
import ModalProject from '../modalProject.js';
import LinkContainer from "../components/LinkContainer";
import moment from "moment";
import NewSharePointLinkModal from "../components/NewSharePointLinkModal";

class SharePointLinksPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            links: [],
            loading: false,
        };
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        this.props.fetchProjectsIfNeeded();

        let _this = this;
        new Parse.Query("SharePointLink")
            .ascending("createdAt")
            .limit(1000)
            .include("project")
            .find()
            .then(links => {
                _this.setState({
                    links,
                    loading: false,
                });
            });

    }

    toggleActive(link) {
        let _this = this;
        this.setState({loading: true});
        link.set("active", !link.get("active"));
        link.save().then(() =>{
            _this.loadData();
        })
    }

    render() {
        const {links, showModal} = this.state;

        return <>

            <NewSharePointLinkModal show={showModal} onHide={() => {this.setState({showModal: false}); this.loadData()}} />

            <div className="card">
                <div className="card-heading">
                    <h2>
                        SharePoint links
                        <Button bsStyle="primary" className="pull-right" onClick={() => this.setState({showModal: true})}>
                            <Glyphicon glyph="plus"/>&nbsp;
                            New link
                        </Button>
                    </h2>
                </div>
                {links.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <td>Project</td>
                            <td>Active</td>
                            <td>Last synchronized</td>
                            <td style={{width: 200}}>Actions</td>
                        </tr>
                        </thead>
                        <tbody>
                        {links.map(link => <tr key={"link" + link.id} className={link.get("active") ? "" : "inactive"}>
                            <td>{link.get("project").get("name")}</td>
                            <td>{link.get("active") ? "Active" : "Not active"}</td>
                            <td>{moment(link.get("lastSynced")).format("DD-MM-YYYY HH:mm")}</td>
                            <td><Button onClick={() => this.toggleActive(link)} disabled={this.state.loading}>
                                {link.get("active") ? "Deactivate" : "Activate"}
                            </Button></td>
                        </tr>)}
                        </tbody>
                    </table>
                ) : (
                    <div className="card-body">
                        <em>Loading...<br/><br/></em>
                    </div>
                )

                }

            </div>
        </>;
    }

}


export default connect(state => ({
}), dispatch => ({
    fetchProjectsIfNeeded: () => dispatch(Action.fetchProjectsIfNeeded())
}))(SharePointLinksPage);