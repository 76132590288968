import React from 'react';
import {connect} from 'react-redux';
import {Glyphicon} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import * as Action from './actions/projects';
import Auth from './auth.js';
import * as Parse from "parse";

const NavLinkHideSidebar = (props) => (
    <NavLink {...props} onClick={() => document.getElementById("sidebar-layout-obfuscator").click()} />
);

class NavbarInstance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            admin: false
        };
    }

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        this.props.fetchProjectsIfNeeded();
        let _this = this;
        let currentUser = Parse.User.current();
        if (currentUser !== null) {
            currentUser.fetch().then(user => {
                _this.setState({admin: user.get("admin")})
            })
        }
    }

    render() {
        const {projects} = this.props;
        let orderedProjects = Object.values(projects.items).sort((a, b) => a.get("name").localeCompare(b.get("name")));
        return (
            <aside className="sidebar-container">
                <div className="sidebar-header">
                </div>
                <div className="sidebar-content">
                    {Auth.isAuthenticated && (
                        <nav className="sidebar-nav">
                            <ul>
                                <li><NavLinkHideSidebar
                                    exact
                                    to="/my-profile"
                                    activeClassName="selected"
                                >
                                    <Glyphicon glyph="user"/> My Profile
                                </NavLinkHideSidebar></li>
                                <li><NavLinkHideSidebar
                                    exact
                                    to="/export"
                                    activeClassName="selected"
                                >
                                    <Glyphicon glyph="export"/> Export
                                </NavLinkHideSidebar></li>
                                {this.state.admin && (
                                    <li><NavLinkHideSidebar
                                        to="/settings"
                                        activeClassName="selected"
                                    >
                                        <Glyphicon glyph="cog"/> Settings
                                    </NavLinkHideSidebar></li>
                                )}
                                {this.state.admin && (
                                    <li><NavLinkHideSidebar
                                        to="/users"
                                        activeClassName="selected"
                                    >
                                        <Glyphicon glyph="user"/> Users
                                    </NavLinkHideSidebar></li>
                                )}
                                {this.state.admin && (
                                    <li><NavLinkHideSidebar
                                        to="/all-projects"
                                        activeClassName="selected"
                                    >
                                        <Glyphicon glyph="list"/> All Projects
                                    </NavLinkHideSidebar></li>
                                )}
                                {this.state.admin && (
                                    <li><NavLinkHideSidebar
                                        to="/sharepoint"
                                        activeClassName="selected"
                                    >
                                        <Glyphicon glyph="cloud-upload"/> SharePoint links
                                    </NavLinkHideSidebar></li>
                                )}
                                <li className="active">
                                    <NavLinkHideSidebar
                                        to="/"
                                        exact
                                        activeClassName="selected"
                                    >
                                        <Glyphicon glyph="th-list"/> Projects
                                    </NavLinkHideSidebar>
                                    {projects.fetched && (
                                        <ul className="sidebar-subnav">
                                            {orderedProjects.map((project) => {
                                                    return (
                                                        <li key={project.id}>
                                                            <NavLinkHideSidebar
                                                                to={"/project/" + project.id}
                                                                activeClassName="selected"
                                                            >
                                                                {project.get("name")}
                                                            </NavLinkHideSidebar>
                                                        </li>
                                                    )
                                                }
                                            )}
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>
            </aside>
        )
    }
}

export default connect((state) => ({
    currentUser: state.users.current,
    projects: state.projects
}), dispatch => ({
    fetchProjectsIfNeeded: () => dispatch(Action.fetchProjectsIfNeeded())
}))(NavbarInstance)