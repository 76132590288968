import * as Action from '../actions/users';
import update from 'immutability-helper';
import {PENDING, FULFILLED, REJECTED} from "../actions/constants";

export function users(state = {
    current: null,
    fetching: false,
    items: {},
    memberships: {},
    newMembership: {
        checked: false,
        checking: false,
    },
    log: [],
}, action) {
    switch (action.type) {
        case Action.SET_ACTIVE_USER:
            return update(state, {
                current: {$set: action.payload}
            });

        case Action.FETCH_USERS + PENDING:
            return update(state, {
                fetching: {$set: true}
            });
        case Action.FETCH_USERS + FULFILLED:
            return update(state, {
                fetching: {$set: false},
                items: {
                    $merge: action.payload
                }
            });

        case Action.FETCH_MEMBERS + PENDING:
            return update(state, {
                    memberships: {
                        $merge: {
                            [action.meta.projectId]:
                                {
                                    fetching: true
                                }
                        }
                    }
                }
            );
        case Action.FETCH_MEMBERS + FULFILLED:
            // let users = {};
            // for (let membership of action.payload) {
            //     let user = membership.get("user");
            //     if (user) users[user.id] = user;
            // }
            return update(state, {
                    memberships: {
                        $merge: {
                            [action.meta.projectId]:
                                {
                                    fetching: false,
                                    items: action.payload,
                                }
                        }
                    },
                    // items: {
                    //     $merge: users
                    // }
                }
            );

        case Action.ADD_USER_TO_PROJECT + PENDING:
            return update(state, {
                    log: {$push: [action.type + ": " + JSON.stringify(action.payload)]},
                    newMembership: {
                        $set: {
                            checked: false,
                            checking: true,
                        }
                    }
                }
            );
        case Action.ADD_USER_TO_PROJECT + FULFILLED:
            if (action.payload !== undefined && action.payload.existing === true) {
                return update(state, {
                    log: {$push: [action.type + ": " + JSON.stringify(action.payload)]},
                    newMembership: {
                        $set: {
                            checked: true,
                            checking: false,
                            existing: true,
                        }
                    }
                })
            } else {
                return update(state, {
                    log: {$push: [action.type + ": " + JSON.stringify(action.payload)]},
                    newMembership: {
                        $set: {
                            checked: true,
                            checking: false,
                            existingUser: action.payload,
                            mail: action.meta.mail,
                        }
                    }
                })
            }

        case Action.CANCEL_ADD_USER_TO_PROJECT:
            return update(state, {
                    newMembership: {
                        $set: {
                            checked: false,
                            checking: false,
                        }
                    }
                }
            );

        case Action.ADD_USER + REJECTED:
            return update(state, {
                    log: {$push: [action.type + ": " + JSON.stringify(action.payload)]},
                    newMembership: {
                        $merge: {
                            error: action.payload.message
                        }
                    }
                }
            );

        case Action.REMOVE_MEMBERSHIP + FULFILLED:
            return update(state, {
                memberships: {
                    [action.payload.get("project").id]:
                        {
                            items: {
                                $apply: items => {
                                    return items.filter(a => a.id !== action.payload.id)
                                }
                            }
                            // $unset: [action.payload.id]

                        }
                }
            });
        case Action.ADD_MEMBERSHIP + FULFILLED:
            return update(state, {
                log: {$push: [action.type + ": " + JSON.stringify(action.payload)]},
                memberships: {
                    [action.payload.get("project").id]:
                        {
                            items: {$push: [action.payload]},
                        }
                },
                newMembership: {
                    $set: {
                        newUserEmail: action.meta.newUserEmail,
                        checked: false,
                        checking: false,
                    }
                }
            });
        default:
            if (action.type.indexOf("USER") !== -1 || action.type.indexOf("MEMBER") !== -1) {
                return update(state, {
                        log: {$push: [action.type + ": " + JSON.stringify(action.payload)]},
                    }
                );
            } else {
                return state;
            }
    }
}