import * as Action from '../actions/roles'
import {PENDING, FULFILLED} from '../actions/constants';
import update from 'immutability-helper';

export function roles(state = {
    items: {},
}, action) {
    switch (action.type) {
        case Action.FETCH_ROLES + PENDING:
            return update(state, {
                items: {
                    $merge: {
                        [action.meta.projectId]: {
                            fetching: true,
                        }
                    }
                }
            });
        case Action.FETCH_ROLES + FULFILLED:
            return update(state, {
                items: {
                    [action.meta.projectId]: {
                        fetching: {$set: false},
                        items: {$set: action.payload}
                    }
                }
            });
        case Action.EDIT_ROLE + PENDING:
        case Action.NEW_ROLE + PENDING:
            return update(state, {
                items: {
                    [action.meta.projectId]: {
                        fetching: {$set: true},
                    }
                }
            });
        case Action.NEW_ROLE + FULFILLED:
            return update(state, {
                items: {
                    [action.meta.projectId]: {
                        fetching: {$set: false},
                        items: {$push: [action.payload]}
                    }
                }
            });
        case Action.EDIT_ROLE + FULFILLED:
            return update(state, {
                items: {
                    [action.meta.projectId]: {
                        fetching: {$set: false},
                        items: {
                            $apply: items => {
                                return items.map(a => (a.id === action.payload.id ? action.payload : a))
                            }
                        }
                    }
                }
            });

        default:
            return state;
    }
};
