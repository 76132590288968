import React from 'react';
import './centric.css';
import './index.css';
import './models/HSERegistration.js';

import version from './resources/version.js'
import {connect} from 'react-redux';
import Projects from './pages/projects.js'
import AllProjects from './pages/all-projects.js'
import BillingPage from './pages/billing.js'
import SettingsPage from './pages/settings.js'
import Project from './pages/project.js'
import EditProject from './pages/edit-project.js'
import NavbarInstance from './navbar.js';
import Sidebar from './sidebar.js';
import SignIn from './sign-in.js';
import Auth from './auth.js'
import User from './pages/user.js'
import Users from './pages/users.js'
import Export from './pages/export.js'
import {
    BrowserRouter as Router,
    Route,
    Redirect
} from 'react-router-dom'
import ResetPassword from "./reset-password";
import SharePointLinksPage from "./pages/sharepoint";
import TermsAndConditionsCheck from "./components/TermsAndConditionsCheck";

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        Auth.isAuthenticated ? (
            <Component {...props}/>
        ) : (
            <Redirect to={{
                pathname: '/sign-in',
                state: {from: props.location}
            }}/>
        )
    )}/>
);

const App = () => (
    <Router>
        <div className="layout-container">
            <TermsAndConditionsCheck>
                <NavbarInstance/>
                <Sidebar/>
                <div className="sidebar-layout-obfuscator" id="sidebar-layout-obfuscator"></div>

                <main className="main-container">
                    <section>
                        <div className="container container-md">
                            <PrivateRoute exact path="/" component={Projects}/>
                            <PrivateRoute exact path="/all-projects" component={AllProjects}/>
                            <PrivateRoute exact path="/sharepoint" component={SharePointLinksPage}/>
                            <PrivateRoute exact path="/my-profile" component={User}/>
                            <PrivateRoute exact path="/export" component={Export}/>
                            <PrivateRoute exact path="/users" component={Users}/>
                            <PrivateRoute exact path="/billing" component={BillingPage}/>
                            <PrivateRoute exact path="/settings" component={SettingsPage}/>
                            <PrivateRoute exact path="/project/:id" component={ProjectPage}/>
                            <PrivateRoute exact path="/project/:id/registration/:registration_id"
                                          component={ProjectPage}/>
                            <PrivateRoute path="/edit-project/:id" component={EditProjectPage}/>
                            <Route path="/sign-in" component={SignIn}/>
                            <Route path="/reset-password" component={ResetPassword}/>
                            <Route path="/set-password" component={ResetPassword}/>
                        </div>
                    </section>
                    <footer><span>2020 - Ventolines Gemba - Powered by <a href="http://www.livemegawatt.com"
                                                                          target="_blank">Live Megawatt</a>. - V {version}</span>
                    </footer>
                </main>
            </TermsAndConditionsCheck>
        </div>
    </Router>
);

const ProjectPage = ({match}) => (
    <Project projectId={match.params.id} registrationId={match.params.registration_id} match={match}/>
);

const EditProjectPage = ({match, location}) => (
    <EditProject projectId={match.params.id} match={match} location={location}/>
);


export default connect()(App);
