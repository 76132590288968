import Parse from 'parse';
import * as MapAction from '../actions/maps'

export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_ALL_PROJECTS = "FETCH_ALL_PROJECTS";

export function fetchProjects() {
    return (dispatch, getState) => {
        let currentUser = Parse.User.current();
        dispatch({
            type: FETCH_PROJECTS,
            payload: new Parse.Query("Membership")
                .equalTo("user", currentUser)
                .include("project")
                .include("role")
                .find()
                .then(objects => objects.reduce((o, n) => {
                    let project = n.get("project");
                    if (!project) return o;
                    project["isHSEManager"] = n.get("type") === 1;
                    project["isAdmin"] = n.get("type") === 2;// || (currentUser && currentUser.id === "f2F7sHmtvB");
                    project["role"] = n.get("role");
                    o[n.get("project").id] = project;
                    return o;
                }, {}))
                .then(objects => {
                    //Should registrations be counted?
                    const activeProjectId = getState().projects.active;
                    if(activeProjectId){
                        dispatch(countRegistrations(activeProjectId, true))
                    }
                    return objects;
                })
        })
    };
}
export function fetchAllProjects() {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_PROJECTS,
            payload: new Parse.Query("Project")
                .find()
        })
    };
}

export function fetchProjectsIfNeeded() {
    return (dispatch, getState) => {
        const {projects} = getState();
        if (Object.keys(projects.items).length === 0 && !projects.fetching) {
            dispatch(fetchProjects());
        }
    };
}

export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";

export function setActiveProjectById(projectId) {
    return (dispatch, state) => {
        const {projects} = state();
        if (projectId !== undefined && (projects.active !== projectId)) {
            dispatch({
                type: SET_ACTIVE_PROJECT,
                projectId,
            });

            dispatch(MapAction.fetchMapOverlayPolys(projectId));
            dispatch(MapAction.fetchMapOverlayPoints(projectId));
            dispatch(countRegistrations(projectId));
        }
    }
}

export const SAVE_PROJECT = "SAVE_PROJECT";
export const SAVE_PROJECT_RELOAD = "SAVE_PROJECT_RELOAD";

export function saveProject(project, reload) {
    return {
        type: reload ? SAVE_PROJECT_RELOAD : SAVE_PROJECT,
        payload: project.save()
    }
}

export const COUNT_REGISTRATIONS = "COUNT_REGISTRATIONS";

export function countRegistrations(projectId, forced) {
    return (dispatch, getState) => {
        const {projects, registrations} = getState();
        let Project = Parse.Object.extend("Project");
        if (forced || Object.keys(projects.items).length !== 0 || projects.fetched) {
            let query = new Parse.Query("HSERegistration")
                .notEqualTo("deleted", true)
                .equalTo("project", new Project({id: projectId}));
            if(registrations.registrationCategoryFilter && registrations.registrationCategoryFilter !== null) {
                query.equalTo("registrationCategory", registrations.registrationCategoryFilter);
            }
            if(registrations.registrationSearchFilter && registrations.registrationSearchFilter !== null
                && registrations.registrationSearchFilter !== "") {
                query.fullText('description', registrations.registrationSearchFilter);
            }
            dispatch({
                type: COUNT_REGISTRATIONS,
                meta: {projectId},
                payload: query
                    .count()
            })
        }
    }
}



export const FETCH_PROJECT_SETTINGS = "FETCH_PROJECT_SETTINGS";
export function fetchProjectSettings(){
    return (dispatch, getState) => {

        const {projects} = getState();
        if (projects.settings && projects.settings.activities && projects.settings.activities.length > 0)return;

        let promises = [];
        promises.push(new Parse.Query("RegistrationActivity")
            .equalTo("active", true)
            // .descending("createdAt")
            .find());
        promises.push(new Parse.Query("RegistrationDanger")
            .equalTo("active", true)
            // .descending("createdAt")
            .find());
        promises.push(new Parse.Query("Windpark")
            // .equalTo("active", true)
            // .descending("createdAt")
            .find()
            .then(objects => objects.reduce((o, n) => {
                o[n.id] = n;
                return o;
            }, {})));
        Promise.all(promises).then((promises) => {
            dispatch({
                type: FETCH_PROJECT_SETTINGS,
                payload: {
                    activities: promises[0],
                    dangers: promises[1],
                    windparks: promises[2],
                }
            })
        })
    };
}