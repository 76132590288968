import React from 'react';
import {MapLegend} from "./MapLegend";

export default class Map extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            center: {lat: props.coordinates.latitude, lng: props.coordinates.longitude},
            zoomLevel: props.zoomLevel,
            googleMapsType: props.googleMapsType,
        };

    }

    componentDidMount() {

        let zoomLevel = 13;
        if (this.state.zoomLevel){
            zoomLevel = this.state.zoomLevel;
        }
        let googleMapsType = 'roadmap';
        if (this.state.googleMapsType){
            googleMapsType = this.state.googleMapsType;
        }

        this.map = new window.google.maps.Map(document.getElementById('project-map'), {
            center: this.state.center,
            zoom: zoomLevel,
            mapTypeId: googleMapsType,
            styles: require("./map_style.json")
        });


        this.map.addListener('zoom_changed', function () {

            for (let marker of this.markers) {
                // console.log(marker);
                // console.log(marker.label.text);
                let label = marker.getLabel();
                label.fontSize = (this.map.getZoom() < 13) ? "0" : "10px";
                marker.setLabel(label)
            }

        }.bind(this));

        if (!this.props.editMode) {
            //Red marker
            this.marker = new window.google.maps.Marker({
                position: this.state.center,
                map: this.map,
                zIndex: 10,
                optimized: false,
            });
        }

        let icon = undefined;

        this.markers = [];
        for (let marker of this.props.markers) {
            // if (!icon) {
                icon = {
                    url: marker.type.icon, // url
                    scaledSize: new window.google.maps.Size(12, 12), // scaled size
                    labelOrigin: new window.google.maps.Point(6, 20), // origin
                    // anchor: new google.maps.Point(0, 0) // anchor
                };
            // }
            this.markers.push(new window.google.maps.Marker({
                position: marker.coordinates,
                map: this.map,
                icon,
                title: marker.description + " (" + marker.type.name + ")",
                label: {
                    text: marker.description,
                    fontSize: "10px",
                },
                zIndex: 5,
            }));
        }

        this.polys = [];
        for (let poly of this.props.polys) {
            // console.log("poly?",poly);
            // if (decriptions.indexOf(poly.))
            if(poly.isPolygon) {
                this.polys.push(new window.google.maps.Polygon({
                    path: poly.coordinates,
                    map: this.map,
                    strokeColor: poly.strokeColor,
                    strokeOpacity: poly.strokeOpacity,
                    strokeWeight: (poly.strokeWidth ? poly.strokeWidth : 1),
                    fillColor: poly.fillColor,
                    fillOpacity: poly.fillOpacity
                }));
            } else {
                this.polys.push(new window.google.maps.Polyline({
                    path: poly.coordinates,
                    map: this.map,
                    strokeColor: poly.strokeColor,
                    strokeOpacity: poly.strokeOpacity,
                    strokeWeight: (poly.strokeWidth ? poly.strokeWidth : 1),
                }));
            }
            // console.log("polys", this.polys);
        }

    }

    componentDidUpdate(){
        if (this.props.editMode){
            this.componentDidMount();
        }
    }

    componentWillReceiveProps(props) {

        this.setState({
            zoomLevel: props.zoomLevel,
            googleMapsType: props.googleMapsType,
        });

        if(props.coordinates) {
            let coordinates = {lat: props.coordinates.latitude, lng: props.coordinates.longitude}
            if(!this.props.editMode) this.marker.setPosition(coordinates);
            this.map.panTo(coordinates);
            this.map.zoomLevel = this.state.zoomLevel;
        }

        //     console.log(window.google);
        //     console.log(this._mapComponent);
        //
        //     if (this._mapComponent) {
        //         this._mapComponent.panTo({ lat: props.coordinates.latitude, lng: props.coordinates.longitude });
        //     }
        //     console.log(props);

    }

    render() {
        let height = this.props.editMode ? 400 : 200;
        return (
            <div>
                <div id="project-map" style={{height: height, width: "100%"}} />
                <MapLegend polys={this.props.polys} />
            </div>
        )
    }
}