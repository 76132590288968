import React from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup, Glyphicon,
    Modal, ProgressBar, Row,
    Table
} from "react-bootstrap";
import update from "immutability-helper";
import {connect} from "react-redux";
import Parse from 'parse';
import moment from "moment";
import * as Action from "../actions/projects";
import {fetchRegistrationsForExport} from "../actions/registrations";
import spinnerImage from "../resources/images/spinner.gif";
import {fetchMapOverlayPoints} from "../actions/maps";

const XLSX = require("xlsx");

class ExportPage extends React.Component {

    state = {
        projects: [],
        periodName: "",

        fromDate: null,
        toDate: null,

        fromDateText: "",
        toDateText: "",

        fromDateParsed: "",
        toDateParsed: "",

        exportIds: new Set(),
        exportRequest: null,

        excelProgress: 0,
        loading: false,
    };

    componentDidMount() {
        this.selectPeriod("lastMonth");

        // let exportRequestId = "51IQ4x7iVQ";
        // this.checkImageExportStatus(exportRequestId)

    }

    checkImageExportStatus(id) {

        console.log("Checking Image Export Status", id);

        const {exportRequest} = this.state;
        if (!exportRequest && !id) return;

        let requestId = id || exportRequest.id;

        let _this = this;
        let Exports = Parse.Object.extend("Exports");
        new Parse.Query(Exports)
            .equalTo("objectId", requestId)
            .first()
            .then(result => {
                _this.setState({exportRequest: result}, () => {
                        if (result.get("working")) {
                            window.setTimeout(_this.checkImageExportStatus.bind(_this), 2000)
                        } else if (result.get("completed")) {
                            let exportUrl = "https://parse.hseq.webzites.link/export/" + requestId;
                            _this.setState({
                                exportUrl,
                                loading: false,
                            });
                            window.location.href = exportUrl;
                        }
                    }
                )
            })
            .catch(console.error);

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleDateChange(event) {

        let _this = this;
        let name = event.target.name;
        let dateMoment = moment(event.target.value, "D-M-YYYY");
        this.setState({
                [name + "Text"]: event.target.value,
                [name]: dateMoment,
                periodName: "custom",
            }, () =>
                _this.updateDateText(name)
        );
    }

    updateDateText(name) {
        if (!this.state[name]) return;
        this.setState({
            [name + "Parsed"]: this.state[name].format("D MMMM YYYY"),
        });
    }

    handleProjectChange(event) {
        let projectId = event.target.value;
        let projects = this.state.projects;
        projects.push(projectId);

        this.setState({
            projects,
        });
    }

    removeProject(id) {
        let projects = [...this.state.projects];
        projects.splice(projects.indexOf(id), 1);
        this.setState({
            projects,
        });
    }

    selectPeriod(periodName) {

        let fromDate = null;
        let toDate = null;

        switch (periodName) {
            case "lastMonth":
                fromDate = moment().startOf("month").subtract(1, "month");
                toDate = moment(fromDate).endOf("month");
                break;
            case "lastYear":
                fromDate = moment().startOf("year").subtract(1, "year");
                toDate = moment(fromDate).endOf("year");
                break;
            case "thisYear":
                fromDate = moment().startOf("year");
                toDate = moment(fromDate).endOf("year");
                break;
        }

        let _this = this;
        this.setState({
                periodName,
                fromDate,
                toDate,
                fromDateText: (fromDate && fromDate.format("D-M-YYYY")) || "",
                toDateText: (toDate && toDate.format("D-M-YYYY")) || "",
                fromDateParsed: "",
                toDateParsed: "",
            }, () => {
                _this.updateDateText("fromDate");
                _this.updateDateText("toDate");
            }
        );

    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    async export(asIssueList) {

        const {fetched, exportItems} = this.props;

        this.setState({loading: true});
        let _this = this;
        let i = 0;

        if (fetched && exportItems && exportItems.length > 0) {
            //export

            let registrations = await Promise.all(exportItems.map(async registration => {

                let user = registration.get("user").get("userInfo");

                let type = registration.get("registrationCategory") ? registration.get("registrationCategory").get("name") :
                    (registration.get("type") ? registration.get("type").get("name") : "");

                let priority = {};
                if (!registration.get("registrationPriority")) {
                    priority = [
                        {
                            color: "#8BC34A",
                            text: "low",
                        }, {
                            color: "#FFC107",
                            text: "medium",
                        }, {
                            color: "#F44336",
                            text: "high",
                        }
                    ][registration.get("priority") || 0];
                } else {
                    let registrationPriority = registration.get("registrationPriority");
                    priority = {
                        color: registrationPriority.get("color"),
                        text: registrationPriority.get("name")
                    }
                }

                let projectName = registration.get("project").get("name");
                let projectId = registration.get("project").id;

                //Assigned users
                let assignedUsers = await registration.get("users").query().include("userInfo").find();
                let assignedUserNames = assignedUsers.map(user => user.get("userInfo").get("name")).join(", ");

                let coordinates = registration.get("coordinates");
                let coordinatesText = "";
                if (coordinates) {
                    coordinatesText = coordinates.latitude + "," + coordinates.longitude;
                }

                let workpackageName = registration.get("workpackage") && registration.get("workpackage").get("name") || "";


                // NOTE: same code is used in SharePoint integration
                let distanceLimit = 1000;
                let distanceWarningLimit = 250; //Should depend on project

                let closestPointText = "";
                if (coordinates && this.props.mapItems[projectId] && this.props.mapItems[projectId].points) {
                    // Find the closest point. Note same code is used in sharepoint
                    let closestPoint = null;
                    let closestPointDistance = 99999999;
                    for (let point of this.props.mapItems[projectId].points) {
                        if (point.get("type").id !== "5uVGWix6zM"){
                            // console.log("Not using point with type: ", point.get("type").id)
                            continue;
                        }  // Turbine point type: 5uVGWix6zM
                        let pointCoords = point.get("coordinates");
                        let distance = this.getDistanceFromLatLon(coordinates.latitude, coordinates.longitude,
                            pointCoords.latitude, pointCoords.longitude);
                        if (distance < distanceLimit && distance < closestPointDistance) {
                            closestPointDistance = distance;
                            closestPoint = point;
                        }
                    }
                    if (closestPoint) {
                        closestPointText = closestPoint.get("description");
                        if (closestPointDistance > distanceWarningLimit) {
                            closestPointText += " (> " + distanceWarningLimit + "m)";
                        }
                    }
                }

                // _this.setState({excelProgress: (i / exportItems.length) * 90});

                i++;

                return {
                    PROJECT: projectName,
                    NR: registration.get("trackingNumberFormatted"),
                    USER: user.get("name"),
                    COORDINATES: coordinatesText,
                    DESCRIPTION: registration.get("description"),
                    TYPE: type,
                    PRIORITY: priority.text,
                    ASSIGNED_TO: assignedUserNames,
                    CLOSEST_POINT: closestPointText,
                    WORKPACKAGE: workpackageName,
                    REACTION: registration.get("reaction"),
                    ACTIVITY: registration.get("registrationActivity") && registration.get("registrationActivity").get("name"),
                    DANGER: registration.get("registrationDanger") && registration.get("registrationDanger").get("name"),
                    IMAGES: registration.get("imageCount"),
                    // CALLBACK: registration.get("callback") === 1,
                    STATUS: registration.get("open") ? "open" : (registration.get("open") === false ? "closed" : null),
                    CREATED: registration.get("createdAt"),
                    UPDATED: registration.get("updatedAt"),
                    // createdAt: moment(registration.get("createdAt")).tz("Europe/Amsterdam").format("D MMM YYYY HH:mm"),
                    // updatedAt: moment(registration.get("updatedAt")).tz("Europe/Amsterdam").format("D MMM YYYY HH:mm"),
                }
            }));

            if (asIssueList) {
                try {
                    var url = "/resources/gemba-issuelist.xlsx";
                    var req = new XMLHttpRequest();
                    req.open("GET", url, true);
                    req.responseType = "arraybuffer";

                    req.onload = function(e) {
                        var data = new Uint8Array(req.response);
                        var workbook = XLSX.read(data, {type:"array"});
                        var first_sheet_name = workbook.SheetNames[0];
                        var worksheet = workbook.Sheets[first_sheet_name];
                        worksheet['E1'].v = "Date: " + moment().format("DD MMMM YYYY");

                        XLSX.utils.sheet_add_aoa(worksheet, registrations.map((reg, i) => [
                            i+1, //a
                            reg.NR,
                            reg.CREATED,
                            reg.USER,
                            reg.WORKPACKAGE, //e

                            reg.TYPE,
                            reg.PRIORITY,
                            reg.CLOSEST_POINT, //h
                            reg.STATUS, //i
                            reg.DESCRIPTION, //j
                            null,
                            null,
                            null,
                            reg.ASSIGNED_TO, //n
                            null, //o
                            null,
                            null,
                            null,
                            reg.REACTION, //s
                        ]), {origin: 2});

                        worksheet['!cols'] = [
                            {wch: 8}, //id
                            {wch: 12},
                            {wch: 12},
                            {wch: 18},
                            {wch: 20}, //wp

                            {wch: 12},
                            {wch: 12},
                            {wch: 16}, //closest point
                            {wch: 10}, //status

                            {wch: 50}, //description
                            {wch: 12},
                            {wch: 12},
                            {wch: 8},

                            {wch: 40}, //assigned to

                            {wch: 8},
                            {wch: 8},
                            {wch: 8},
                            {wch: 8},

                            {wch: 40}, //reaction
                        ];
                        // for (let j = 0; j < registrations.length; j++) {
                        //     let registration = registrations[j];
                        //     let i = j + 2;
                        //     console.log('A' + i);
                        //     worksheet['A' + i].v = j+1;
                        //     worksheet['B' + i].v = registration.NR;
                        //     worksheet['C' + i].v = registration.CREATED;
                        //     worksheet['D' + i].v = registration.USER;
                        //     worksheet['E' + i].v = registration.WORKPACKAGE;
                        //     worksheet['F' + i].v = registration.TYPE;
                        //     worksheet['G' + i].v = registration.PRIORITY;
                        //     worksheet['H' + i].v = registration.CLOSEST_POINT;
                        //     worksheet['I' + i].v = registration.STATUS;
                        //     // worksheet['K' + i].v = registration.DESCRIPTION;
                        //     worksheet['N' + i].v = registration.ASSIGNED_TO;
                        //     worksheet['S' + i].v = registration.REACTION;
                        // }

                        XLSX.writeFile(workbook, "export.xlsx");

                        _this.setState({loading: false, excelProgress: 100});

                    }

                    req.send();
                } catch (e) {
                    console.error(e);
                    _this.setState({loading: false});
                }
                return;
            }


            try {
                let ws = XLSX.utils.json_to_sheet(
                    registrations//,
                    // {
                    //     header:["description","project","createdAt","type"]
                    // }
                );

                ws['!cols'] = [
                    {wch: 18},
                    {wch: 10},
                    {wch: 12},
                    {wch: 30},
                    {wch: 20},

                    {wch: 11},
                    {wch: 12},
                    {wch: 20},
                    {wch: 25},

                    {wch: 8},
                    {wch: 8},
                    {wch: 12},
                    {wch: 12},
                ];

                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws); //name

                XLSX.writeFile(wb, "export.xlsx");


                _this.setState({loading: false, excelProgress: 100});

            } catch (e) {
                console.error(e);
                _this.setState({loading: false});
            }


        }
    }

    getDistanceFromLatLon(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2-lon1);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c * 1000; // Distance in m
        return d;
    }

    deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    registrationCheckboxChanged(e, id) {

        let on = false;
        let exportId = id;
        if (e === "toggle") {
            on = !(this.state.exportIds.has(id));
        } else {
            on = e.target.checked;
            exportId = e.target.name;
        }

        this.setState({
            exportIds: update(this.state.exportIds, {
                [on ? "$add" : "$remove"]: [exportId],
            })
        });

    }

    exportImages() {

        console.log("Export Images");
        let {exportIds} = this.state;
        let _this = this;
        this.setState({loading: true});

        let Exports = Parse.Object.extend("Exports");
        let exportRequest = new Exports();
        exportRequest.set("registrations", Array.from(exportIds));
        exportRequest.set("requestedBy", Parse.User.current());
        exportRequest.save().then(exportRequest => {
            _this.setState({exportRequest}, _this.checkImageExportStatus.bind(_this))
        });

    }

    selectAll() {

        // console.log(this.props.exportItems, this.props.exportItems.map(registration => registration.id));

        this.setState({
            exportIds: new Set(this.props.exportItems.map(registration => registration.id) || [])
        });

    }

    save(e) {
        e.preventDefault();
        const {projects, fromDate, toDate} = this.state;

        this.setState({exportIds: new Set()});

        this.props.fetchRegistrationsForExport(projects, fromDate, toDate);

        for (let projectId of projects) {
            this.props.fetchMapPoints(projectId);
        }

        return false;

    }

    render() {

        const {projects, exportItems, fetched, fetching} = this.props;
        const {fromDate, toDate, fromDateText, toDateText, fromDateParsed, toDateParsed, periodName, exportRequest, exportIds, exportUrl, loading, excelProgress} = this.state;
        const selectedProjects = this.state.projects;
        const sortedProjects = Object.values(projects || {}).sort((a, b) => a.get("name").localeCompare(b.get("name")));

        return (
            <div className="card">
                <div className="card-heading">
                    <h2>
                        Export
                    </h2>
                </div>
                <div className="card-body">

                    <Form horizontal onSubmit={this.save.bind(this)}>

                        <FormGroup controlId="formBasicText">
                            <Col componentClass={ControlLabel} sm={2}>Projects</Col>
                            <Col sm={10}>

                                <ul>
                                    {selectedProjects.map(projectId => {
                                        let project = projects[projectId];
                                        return <li key={"selProj" + project.id}>
                                            {project.get("name")} &nbsp;&nbsp;&nbsp;
                                            <Glyphicon glyph="remove" onClick={() => this.removeProject(project.id)}/>
                                        </li>
                                    })}
                                </ul>

                                <FormControl componentClass="select" placeholder="Select project" name="addProjectId"
                                             onChange={this.handleProjectChange.bind(this)}>
                                    <option value="undefined">- select a project to add to export -</option>
                                    {sortedProjects
                                        .filter(proj => selectedProjects.indexOf(proj.id) === -1)
                                        .map(project =>
                                            <option key={project.id} value={project.id}>
                                                {project.get("name")}
                                            </option>
                                        )}
                                </FormControl>
                            </Col>
                        </FormGroup>


                        <FormGroup controlId="formBasicText">
                            <Col componentClass={ControlLabel} sm={2}>Period</Col>
                            <Col sm={10}>

                                <ButtonGroup>
                                    <Button active={periodName === "lastMonth"}
                                            onClick={() => this.selectPeriod("lastMonth")}>Last month</Button>
                                    <Button active={periodName === "lastYear"}
                                            onClick={() => this.selectPeriod("lastYear")}>Last year</Button>
                                    <Button active={periodName === "thisYear"}
                                            onClick={() => this.selectPeriod("thisYear")}>This year</Button>
                                    <Button active={periodName === "all"}
                                            onClick={() => this.selectPeriod("all")}>All time</Button>
                                    <Button active={periodName === "custom"}
                                            onClick={() => this.selectPeriod("custom")}>Custom</Button>
                                </ButtonGroup>

                                <br/>

                                <Col sm={3}>
                                    <FormGroup
                                        controlId="fromDate">
                                        <ControlLabel>Start date</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="fromDate"
                                            value={fromDateText}
                                            placeholder="d-m-yyy"
                                            onChange={this.handleDateChange.bind(this)}/>
                                    </FormGroup>
                                    {fromDateParsed}
                                </Col>

                                <Col sm={1}/>

                                <Col sm={3}>
                                    <FormGroup
                                        controlId="fromDate">
                                        <ControlLabel>End date</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="toDate"
                                            value={toDateText}
                                            placeholder="d-m-yyy"
                                            onChange={this.handleDateChange.bind(this)}/>
                                    </FormGroup>
                                    {toDateParsed}
                                </Col>
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col smOffset={2} sm={10}>
                                <Button bsStyle="primary" type="submit" disabled={fetching}>
                                    {fetching ? "Loading..." : "Search"}
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>

                    {fetched && !fetching && <div style={{borderTop: '2px solid #ccc'}}>

                        <Col componentClass={ControlLabel} sm={2}>Results</Col>
                        <Col sm={10}>
                            {exportItems.length} registrations found.<br/><br/>

                            {loading ? <>Loading...</> : <>
                            <Button bsStyle="primary" onClick={() => this.export()} disabled={loading}>
                                Download registrations as Excel file
                            </Button>{" "}
                            <Button bsStyle="primary" onClick={() => this.export(true)} disabled={loading}>
                                Download Excel issue list
                            </Button>
                            </>}<br/>

                            <hr/>
                            <strong>Select registrations to download images</strong><br/>

                            {!fetching && <Button onClick={() => this.selectAll()}>
                                Select all
                            </Button>}
                            <Table striped bordered condensed hover>
                                <tbody>
                                {exportItems.map((registration, i) => <tr
                                        key={"reg" + i + '.' + registration.get("trackingNumberFormatted")}
                                        onClick={() => this.registrationCheckboxChanged('toggle', registration.id)}
                                    >
                                        <td style={{width: '30px'}}><input type="checkbox"
                                                                           checked={exportIds.has(registration.id)}
                                                                           name={registration.id}
                                                                           onChange={(e) => this.registrationCheckboxChanged(e)}/>
                                        </td>
                                        <td className="registration-thumbnail-container">
                                            <div className="registration-thumbnail"
                                                 style={{backgroundImage: "url('" + registration.getThumbnailUrl() + "')"}}>
                                                <div>{registration.get("imageCount") > 0 ? registration.get("imageCount") : "no"} image{registration.get("imageCount") === 1 ? "" : "s"}</div>
                                            </div>
                                        </td>
                                        <td>{registration.get("trackingNumberFormatted")}</td>
                                        <td>{moment(registration.get("createdAt")).format("D MMM YYYY HH:mm")}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>

                            {!fetching && <Button onClick={() => this.selectAll()}>
                                Select all
                            </Button>}
                            &nbsp;
                            <Button bsStyle="primary" onClick={() => this.exportImages()}
                                    disabled={loading || !exportIds || exportIds.size === 0}>
                                {loading ? "Loading..." : "Download selected images"}
                            </Button><br/><br/>
                        </Col>
                    </div>}

                    <br style={{clear: "both"}}/>

                    {exportRequest && <Alert>

                        <h3>Image export status</h3>
                        Exporting images from {exportRequest.get("registrations").length} registrations...<br/><br/>

                        {exportRequest.get("working") &&
                        <img src={spinnerImage} alt="Loading..." className="spinner-centered bg-white"/>}
                        {exportRequest.get("completed") && <p>
                            Download started... If file does not download <a href={exportUrl} target="_blank">click
                            here</a> to try again.
                        </p>}

                    </Alert>}

                    <br style={{clear: "both"}}/>

                </div>
            </div>
        );
    }
}

export default connect(state => ({
    projects: state.projects.items,
    exportItems: state.registrations.exportItems,
    fetched: state.registrations.exportFetched,
    fetching: state.registrations.exportFetching || state.maps.fetching,
    mapItems: state.maps.items || [],
    // projects: {
    //     "proj1": new (Parse.Object.extend("Project"))().set("id", "proj1").set("name", "Projectnaa m"),
    //     "proj2": new (Parse.Object.extend("Project"))().set("id", "proj2").set("name", "project 2"),
    // }
}), dispatch => ({
    fetchRegistrationsForExport: (projects, fromDate, toDate) => dispatch(fetchRegistrationsForExport(projects, fromDate, toDate)),
    fetchMapPoints: (projectId) => dispatch(fetchMapOverlayPoints(projectId)),
}))(ExportPage)