import React from 'react';
import {connect} from "react-redux";


class WorkpackageSelector extends React.Component {

    handleChange(event) {
        let activitiesById = this.props.workpackages.reduce((o, n) => {o[n.id] = n; return o;}, {});
        this.props.registration.set("workpackage", activitiesById[event.target.value]);
        this.forceUpdate();
    }

    render() {
        const {editing, registration, workpackages, role, workpackageRequired, bypassRole} = this.props;
        let activeId = registration.get("workpackage") && registration.get("workpackage").id;

        let allowedWorkpackages = role && role.get("workpackages") || [];

        return editing ? <>
            <select value={activeId || ""} onChange={(e) => {this.handleChange(e)}}
                    style={{borderColor: activeId || !workpackageRequired ? "" : "red"}}>
                <option>None</option>
                {workpackages.filter(a => bypassRole || allowedWorkpackages.indexOf(a.id) !== -1).map((type, index) => {
                    return (
                        <option key={"act" + index} value={type.id}>
                            {type.get("name")}
                        </option>
                    )
                })}
            </select>
        </> : (
            <div>
                {registration.getWorkpackageDescription("None")}
            </div>
        )

    }
}

export default connect(state => ({
    workpackageRequired: state.projects.active && state.projects.items[state.projects.active]
        && state.projects.items[state.projects.active].get("workpackageRequired") || false,
    role: state.projects.active && state.projects.items[state.projects.active]
        && state.projects.items[state.projects.active].role,
    workpackages: state.workpackages.items[state.projects.active] &&
        state.workpackages.items[state.projects.active].items || [],
}), dispatch => ({
}))(WorkpackageSelector);