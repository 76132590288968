import React from 'react';
import Parse from 'parse';
import {Link, withRouter} from 'react-router-dom';
import Auth from './auth.js';
import {Label} from "react-bootstrap";

const AuthButton = withRouter(({history}) => (
    Auth.isAuthenticated ? (
        <li><a onClick={() => {
            Auth.signOut().then(() => history.push('/'))
        }}>Sign out</a></li>
    ) : (
        <li><Link to="/sign-in">Sign in</Link></li>
    )
));

export default class NavbarInstance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            admin: false,
            username: null,
        };
    }

    componentDidMount() {

        let _this = this;
        let currentUser = Parse.User.current();
        if (currentUser !== null) {
            currentUser.fetch().then(user => {
                _this.setState({
                    admin: user.get("admin"),
                    username: user.get("username")
                })
            })
        }

    }

    toggleSidebar() {
        let root = document.getElementById("root");
        root.className += " sidebar-visible";
        document.getElementById("sidebar-layout-obfuscator").onclick = function(){
            root.className = root.className.replace(" sidebar-visible", "");
        }
    }


    // var layoutContainer = $('.layout-container');
    // var $body = $('body');
    // // Handler to toggle sidebar visibility on mobile
    // $('#sidebar-toggler').click(function(e) {
    //     e.preventDefault();
    //     layoutContainer.toggleClass('sidebar-visible');
    //     // toggle icon state
    //     $(this).parent().toggleClass('active');
    // });

    render() {
        return (
            <header className="header-container">
                <nav>
                    <ul className="visible-xs visible-sm">
                        <li><a id="sidebar-toggler" onClick={this.toggleSidebar} className="menu-link menu-link-slide"><span><em></em></span></a></li>
                    </ul>
                    <h2 className="header-title">Gemba</h2>
                    <ul className="pull-right">
                        {this.state.username && (
                            <li style={{opacity: 0.9, marginRight: '10px'}}>
                                Signed in as {this.state.username}
                            </li>
                        )}
                        <li>
                            {this.state.admin && (
                                <Label>ADMIN</Label>
                            )}
                        </li>
                        <AuthButton />
                    </ul>
                </nav>

            </header>
        )
    }
};