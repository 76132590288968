import React from 'react';
import {connect} from "react-redux";
import * as Action from '../actions/registrationTypes'
import {Glyphicon, Label, OverlayTrigger, Tooltip} from "react-bootstrap";

export const OLD_TYPE_REGISTRATION_CATEGORY_ID = "jPHr8pdNKr";

class TypeSelector extends React.Component {

    constructor(props) {
        super(props);
        props.fetchTypes();
        if(props.registration.get("registrationCategory")) {
            this.props.setActiveType(props.registration.get("registrationCategory"));
        } else if(props.registration.get("type")) {
            //Old type
            this.props.setActiveType(props.types[OLD_TYPE_REGISTRATION_CATEGORY_ID]);
        }
        this.state = {
            category: props.registration.get("registrationCategory"),
        }
    }

    handleChange(event) {
        const {types} = this.props;
        let type = types[event.target.value];
        if (this.props.registration.get("registrationCategory") &&
            type.id !== this.props.registration.get("registrationCategory").id) {
            // Check if priority danger and activity are still possible
            if (this.props.registration.get("registrationPriority") &&
                (!type.get("registrationPriorities") ||
                type.get("registrationPriorities").length === 0 ||
                type.get("registrationPriorities").indexOf(this.props.registration.get("registrationPriority").id) === -1)) {
                this.props.registration.set("registrationPriority", null);
            }
            if (this.props.registration.get("registrationDanger") &&
                (!type.get("registrationDangers") ||
                type.get("registrationDangers").length === 0 ||
                type.get("registrationDangers").indexOf(this.props.registration.get("registrationDanger").id) === -1)) {
                this.props.registration.set("registrationDanger", null);
            }
            if (this.props.registration.get("registrationActivity") &&
                (!type.get("registrationActivities") ||
                type.get("registrationActivities").length === 0 ||
                type.get("registrationActivities").indexOf(this.props.registration.get("registrationActivity").id) === -1)) {
                this.props.registration.set("registrationActivity", null);
            }
        }

        this.props.registration.set("registrationCategory", type);
        this.props.setActiveType(type);
    }

    componentWillReceiveProps(newProps) {
        this.setState({category: newProps.registration.get("registrationCategory")});
        if(newProps.registration.get("registrationCategory")){
            let registrationCategory = newProps.registration.get("registrationCategory");
            if(registrationCategory !== this.props.registration.get("registrationCategory")){
                this.props.setActiveType(registrationCategory);
            }
        } else if(newProps.registration.get("type") && newProps.editing) {
            //Old type -> should convert?
            let type = newProps.types[OLD_TYPE_REGISTRATION_CATEGORY_ID];
            this.props.registration.set("registrationCategory", type);
            this.props.setActiveType(type);
        }
    }

    render() {

        const {types, editing, registration, role, bypassRole} = this.props;
        const tooltip = (
            <Tooltip id="tooltip">
                <strong>Warning:</strong> When saving this report it will be converted to a new type.
            </Tooltip>
        );

        let showWarning = false;
        let registrationCategory = registration.get("registrationCategory");
        let currentCategoryId = registrationCategory && registrationCategory.id;
        if (!this.state.category && registration.id) {
            //Set New cat Safety as fall-back for old registrations
            currentCategoryId = OLD_TYPE_REGISTRATION_CATEGORY_ID;
            showWarning = true;
        }
        // let registrationType = registration.get("type");

        let allowedCategories = role && role.get("categories") || [];
        let categories = Object.keys(types).map(key => types[key])
            .filter(type => bypassRole || allowedCategories.indexOf(type.id) !== -1);

        return editing ? (
            <div>
                <select defaultValue={currentCategoryId} onChange={(e) => {
                    this.handleChange(e)
                }}>
                    {categories.map((type, index) => {
                        return (
                            <option key={"type" + index} value={type.id}>
                                {type.get("name")}
                            </option>
                        )
                    })}
                </select>
                {showWarning && (
                    <OverlayTrigger placement="left" overlay={tooltip}>
                        <Label bsStyle="danger" style={{marginLeft:10}}><Glyphicon glyph="exclamation-sign" /></Label>
                    </OverlayTrigger>
                )}
            </div>
        ) : (
            <div>
                {registration.getTypeDescription()}
            </div>
        )

    }
}

export default connect(state => ({
    types: state.registrationTypes.items,
    role: state.projects.active && state.projects.items[state.projects.active]
        && state.projects.items[state.projects.active].role,
}), dispatch => ({
    fetchTypes: project => dispatch(Action.fetch()),
    setActiveType: type => dispatch(Action.setActiveType(type))
}))(TypeSelector);