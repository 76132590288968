import React from 'react';
import {connect} from "react-redux";


class ActivitySelector extends React.Component {

    handleChange(event) {
        let activitiesById = this.props.activities.reduce((o, n) => {o[n.id] = n; return o;}, {});
        this.props.registration.set("registrationActivity", activitiesById[event.target.value]);
        this.forceUpdate();
    }

    render() {
        const {editing, registration, activities} = this.props;
        let activeId = registration.get("registrationActivity") && registration.get("registrationActivity").id;
        return editing ? <>
            <select value={activeId || ""} onChange={(e) => {this.handleChange(e)}}>
                <option>None</option>
                {activities.map((type, index) => {
                    return (
                        <option key={"act" + index} value={type.id}>
                            {type.get("name")}
                        </option>
                    )
                })}
            </select>
        </> : (
            <div>
                {registration.getActivityDescription("None")}
            </div>
        )

    }
}

export default connect(state => ({
    activities: (state.registrationTypes.active && state.registrationTypes.active.get("registrationActivities") || [])
        .map(d => state.projects.settings.activities.find(da => da.id === d))
        .filter(
            (d) => d && d.get("active")
        ).sort((a, b) => a.get("name").localeCompare(b.get("name")))
}), dispatch => ({
}))(ActivitySelector);