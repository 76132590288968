import React from 'react';

export default class ReactionField extends React.Component {
    handleChange(event) {
        this.props.registration.set("reaction", event.target.value)
    }

    render() {
        const {editing, registration} = this.props;

        return (
            <div className="registration-reaction">
                <h4>Reaction</h4>
                {editing  ? (
                    <textarea defaultValue={registration.get("reaction")} onChange={(e) => {this.handleChange(e)}} />
                ) : (
                    registration.get("reaction") || <em>No reaction provided yet</em>
                )}
            </div>
        );

    }
}