import React, {useEffect} from "react";
import {connect} from "react-redux";
import * as UserAction from "../actions/users";
import NavbarInstance from "../navbar";
import User from "../pages/user";
import Parse from "parse";

const TermsAndConditionsCheck = (props) => {

    let acceptedTerms = false;

    const currentUser = Parse.User.current();
    useEffect(() => {
        if (currentUser) props.fetchCurrentUserInfo();
    }, [currentUser]);

    if (currentUser) {
        const userInfo = currentUser.get("userInfo");
        const acceptedTermsVersion = userInfo.get("acceptedTerms");
        if (acceptedTermsVersion && acceptedTermsVersion > 0) {
            acceptedTerms = true;
        }
    }

    return <>
        {!currentUser || acceptedTerms ?
            props.children :
            <>
                <NavbarInstance/>
                <div className="sidebar-layout-obfuscator" id="sidebar-layout-obfuscator"></div>

                <main className="main-container">
                    <section>
                        <div className="container container-md">
                            <User />
                        </div>
                    </section>
                </main>

            </>}
    </>;

}

export default connect(state => ({
    currentUser: state.users
}), dispatch => ({
    fetchCurrentUserInfo: () => dispatch(UserAction.fetchCurrentUserInfo()),
}))(TermsAndConditionsCheck)