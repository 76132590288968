import React from 'react';
import ReactClass from 'create-react-class';
import {Button, FormGroup, FormControl, ControlLabel, Modal, Form, Alert, Label, Checkbox, Col} from 'react-bootstrap';
import * as UserAction from "./actions/users";
import {connect} from "react-redux";
import spinnerImage from "./resources/images/spinner.gif";

let component = ReactClass({

    getInitialState() {
        return {
            phonenumber: "",
            name: "",
            showModal: false,
            alert: "",
            loading: false,
            sendEmail: false,
        };
    },

    closeModal() {
        this.props.cancelAddUserToProject();
    },

    handleAlertDismiss() {
        this.setState({alert: ""});
    },

    componentWillReceiveProps(newProps) {
        let error = newProps.error;
        if (error !== undefined && error !== "") {
            this.setState({alert: error, loading: false});
        }
    },

    save(event) {
        event.preventDefault();

        // if (this.state.phonenumber === "") {
        //     this.setState({alert: "Please enter a phone number"});
        //     return;
        // }
        // if (this.state.name === "") {
        //     this.setState({alert: "Please enter a name"});
        //     return;
        // }

        this.setState({alert: "", loading: true});
        this.props.addUser(this.props.mail.toLowerCase(), this.state.name, this.state.phonenumber, this.props.project, this.state.sendEmail);



        return false;
    },

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    },

    handleCheckboxChange(event) {
        this.setState({[event.target.name]: event.target.checked});
    },

    render() {
        return (
            <Modal show={this.props.show} onHide={this.closeModal}>
                <Form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title>New user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert bsStyle="warning">
                            Note: There is no existing user with this e-mail address. Create a new user:
                        </Alert>
                        {this.state.alert !== "" &&
                            <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                                <h4>Error</h4>
                                <p>{this.state.alert}</p>
                            </Alert>
                        }
                        {/*<FormGroup*/}
                        {/*    controlId="formBasicText"*/}
                        {/*>*/}
                        {/*    <ControlLabel>Name</ControlLabel>*/}
                        {/*    <FormControl*/}
                        {/*        type="text"*/}
                        {/*        name="name"*/}
                        {/*        value={this.state.name}*/}
                        {/*        placeholder="Full name"*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*    />*/}
                        {/*</FormGroup>*/}
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>E-mail address</ControlLabel><br/>
                            {this.props.mail.toLowerCase()} {this.props.mail !== this.props.mail.toLowerCase() &&
                            <Label bsStyle="warning">Note: the e-mail address has been changed to lower case.</Label>}
                        </FormGroup>
                        {/*<FormGroup*/}
                        {/*    controlId="formBasicText"*/}
                        {/*>*/}
                        {/*    <ControlLabel>Phone number</ControlLabel>*/}
                        {/*    <FormControl*/}
                        {/*        type="text"*/}
                        {/*        name="phonenumber"*/}
                        {/*        value={this.state.phonenumber}*/}
                        {/*        placeholder="+31612345678"*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*    />*/}
                        {/*</FormGroup>*/}
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <FormGroup>
                                    <Checkbox
                                        type="checkbox"
                                        name="sendEmail"
                                        value={this.state.sendEmail}
                                        onChange={this.handleCheckboxChange}
                                    >
                                        Send e-mail to new user set password
                                    </Checkbox>
                            </FormGroup>
                        </FormGroup>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal}>
                            {this.state.success ? "Close" : "Cancel"}
                        </Button>
                        {!this.state.success && (this.state.loading ? (
                            <img src={spinnerImage} alt="Loading..."/>
                        ) : (
                            <Button bsStyle="primary" type="submit">Create user</Button>
                        ))}
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    },

});

export default connect(state => ({}
), dispatch => ({
    cancelAddUserToProject: () => dispatch(UserAction.cancelAddUserToProject()),
    addUser: (mail, name, phone, project, sendEmail) => dispatch(UserAction.addUser(mail, name, phone, project, sendEmail)),
}))(component);