import React from 'react';
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import * as Parse from "parse";
import moment from "moment";

export default class UserRow extends React.Component {

    constructor(props) {
        super(props);
        this.resetPassword = this.resetPassword.bind(this);
        this.delete = this.delete.bind(this);

        this.state = {
            //     ...props.user,
            //     // email: props.user.object.get("username"),
            //     // createdAt: props.user.object.get("createdAt"),
            //     // name: userInfo ? userInfo.get("name") : "",
            message: null,
            loading: null,
        };
    }

    resetPassword() {
        if (!window.confirm("Reset password?"))return;
        let _this = this;
        _this.setState({loading: true});
        Parse.User.requestPasswordReset(this.props.user.object.get("username")).then(result => {
            _this.props.passwordResetSuccess();
        });
    }
    delete() {
        if (!window.confirm("Are you sure you want to delete this user?"))return;
        let _this = this;
        _this.setState({loading: true});
        this.props.user.object.destroy({useMasterKey: true}).then((result) => {
            window.location.reload();
        })
    }

    render() {
        const {user, memberships} = this.props;

        const popover = (
            <Popover id="popover-positioned-bottom" title="Popover bottom">
                {memberships && memberships.map(m => m.get("project") && <div>
                    <a href={"/project/" + m.get("project").id}>
                        {m.get("project").get("name")}
                    </a>
                </div>)}
            </Popover>
        );

        return (
            <tr onClick={() => {
                console.log(user, memberships)
            }}
                className={!memberships || memberships.length === 0 ? "inactive" : ""}>
                <td>
                    {user.name}
                    {user.object.get("admin") &&
                        <div><span className="label label-info">Administrator</span></div>}
                </td>
                <td style={{whiteSpace: "pre"}}>
                    {user.email}
                </td>
                <td>
                    {moment(user.createdAt).format("DD-MM-YYYY HH:mm")}
                </td>
                <td>

                    <OverlayTrigger trigger="click"
                                    overlay={popover}
                                    rootClose
                                    placement="bottom">
                        <Button disabled={!memberships || memberships.length === 0}>
                            {memberships && memberships.length || "0"} projects
                        </Button>
                    </OverlayTrigger>

                </td>
                <td>
                    {this.state.loading ? "Loading..." :
                        <Button onClick={this.resetPassword}>Reset password</Button>}
                    {this.state.message && (
                        <div><em>{this.state.message}</em></div>
                    )}

                    {process.env.REACT_APP_MASTER_KEY &&
                        <Button onClick={this.delete}>Delete</Button>
                    }
                </td>
            </tr>
        );
    }

}