import React from 'react';
import {connect} from "react-redux";
import * as WPActions from "../actions/roles";
import {Alert, Button, ControlLabel, Form, FormControl, FormGroup, Modal} from "react-bootstrap";
import spinnerImage from "../resources/images/spinner.gif";


class ProjectRoles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            newRoleCreate: false,
            newRoleRead: false,
            newRoleName: "",
            newRoleCategories: [],
            newRoleWorkpackages: [],
            newRoleWindparks: [],
            editing: null,
        };
    }

    close() {
        this.setState({
            modalOpen: false,
            editing: null,
            newRoleCreate: false,
            newRoleRead: false,
            newRoleName: "",
            newRoleCategories: [],
            newRoleWorkpackages: [],
            newRoleWindparks: [],
        });
    }

    editRole(role) {

        this.setState({
            editing: role,
            newRoleName: role.get('name'),
            newRoleCreate: role.get('create'),
            newRoleRead: role.get('read'),
            newRoleCategories: role.get('categories') || [],
            newRoleWorkpackages: role.get('workpackages') || [],
            newRoleWindparks: role.get('windparks') || [],
            modalOpen: true,
        })
    }

    addNewRole() {
        this.close()
        if (this.state.editing) {
            let role = this.state.editing;
            role.set("create", this.state.newRoleCreate);
            role.set("read", this.state.newRoleRead);
            role.set("name", this.state.newRoleName);
            role.set("categories", this.state.newRoleCategories);
            role.set("workpackages", this.state.newRoleWorkpackages);
            role.set("windparks", this.state.newRoleWindparks);
            this.props.editRole(this.props.project.id, role);
            this.setState({editing: null})
        } else {
            this.props.addNewRole(this.props.project.id, {
                "create": this.state.newRoleCreate,
                "read": this.state.newRoleRead,
                "name": this.state.newRoleName,
                "categories": this.state.newRoleCategories,
                "workpackages": this.state.newRoleWorkpackages,
                "windparks": this.state.newRoleWindparks,
            });
        }

    }

    removeRole() {
        if (window.confirm("Are you sure you want to delete this role? (This can be undone by a system administrator)")) {
            this.close()
            let role = this.state.editing;
            role.set("deleted", true);
            this.props.editRole(this.props.project.id, role);
            this.setState({editing: null})
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleAccessChange(event, type) {
        this.setState({["newRole" + type]: event.target.checked});
    }

    handleCategoryChange(event, type) {
        let categories = [...this.state.newRoleCategories].filter(c => c !== type.id);
        if (event.target.checked) categories.push(type.id);
        this.setState({["newRoleCategories"]: categories});
    }

    handleWorkpackagesChange(event, type) {
        let workpackages = [...this.state.newRoleWorkpackages].filter(c => c !== type.id);
        if (event.target.checked) workpackages.push(type.id);
        this.setState({["newRoleWorkpackages"]: workpackages});
    }

    handleWindparkChange(event, type) {
        let windparks = [...this.state.newRoleWindparks].filter(c => c !== type.id);
        if (event.target.checked) windparks.push(type.id);
        this.setState({["newRoleWindparks"]: windparks});
        console.log(this.state.newRoleWindparks);
    }

    render() {

        let {roles, workpackages, categories, windparks, workpackageRequired, project} = this.props;
        let {
            modalOpen, newRoleName, newRoleCategories, newRoleWorkpackages,
            newRoleCreate, newRoleRead, newRoleWindparks,
            editing
        } = this.state;

        return <>
            <Modal show={modalOpen} onHide={() => this.close()}>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    this.addNewRole();
                    return false;
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Role details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Role name</ControlLabel>
                            <FormControl
                                type="text"
                                name="newRoleName"
                                value={newRoleName}
                                placeholder="Example role"
                                onChange={this.handleChange.bind(this)}
                            />
                        </FormGroup>

                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Access</ControlLabel><br/>
                            <div><label><input type="checkbox"
                                               checked={newRoleCreate}
                                               onChange={(e) => this.handleAccessChange(e, "Create")}/>
                                {" "} Create <em style={{fontWeight: "normal"}}>(User will also be able to read all
                                    their own reports)</em>
                            </label></div>
                            <div>
                                <label><input type="checkbox"
                                              checked={newRoleRead}
                                              onChange={(e) => this.handleAccessChange(e, "Read")}/>
                                    {" "} Read <span style={{fontWeight: "normal"}}>reports from other users</span>
                                </label>
                            </div>
                        </FormGroup>

                        {!newRoleCreate && !newRoleRead ? <Alert bsStyle="warning">
                                <em>Note: No reports will be visible</em></Alert> :
                            <Alert bsStyle="info">
                                User will only be able to

                                {newRoleCreate && <strong> create reports with these properties</strong>}
                                {newRoleCreate && newRoleRead && " and"}
                                {newRoleRead &&
                                    <strong> read reports from other users if they have these properties</strong>}:

                            </Alert>}

                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Categories</ControlLabel>
                            <div>
                                <label><input type="checkbox"
                                          checked={newRoleCategories.length === 0}
                                          disabled />
                                {" "}All categories
                            </label>
                            </div>
                            {Object.values(categories).map(type => <div key={type.id}>
                                    <label><input type="checkbox"
                                                  checked={newRoleCategories.includes(type.id)}
                                                  onChange={(e) => this.handleCategoryChange(e, type)}/>
                                        {" "}{type.get("name")}
                                    </label>
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Workpackages {workpackageRequired ? " (Workpackage is required for this project!)" : ""}</ControlLabel>
                            <div>
                                <label><input type="checkbox"
                                              checked={newRoleWorkpackages.length === 0}
                                              disabled />
                                    {" "}All workpackages
                                </label>
                            </div>
                            {workpackages.items && workpackages.items
                                .map(type => <div key={type.id}>
                                        <label><input type="checkbox"
                                                      checked={newRoleWorkpackages.includes(type.id)}
                                                      onChange={(e) => this.handleWorkpackagesChange(e, type)}/>
                                            {" "}{type.get("name")} {type.get("deleted") && "(Deleted)"}
                                        </label>
                                    </div>
                                )}
                        </FormGroup>
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Windparks</ControlLabel>
                            <div>
                                <label><input type="checkbox"
                                              checked={newRoleWindparks.length === 0}
                                              disabled />
                                    {" "}All windparks
                                </label>
                            </div>
                            {windparks.filter(a => a.get("project").id === project.id)
                                .map(type => <div key={type.id}>
                                        <label><input type="checkbox"
                                                      checked={newRoleWindparks.includes(type.id)}
                                                      onChange={(e) => this.handleWindparkChange(e, type)}/>
                                            {" "}{type.get("name")} {type.get("deleted") && "(Deleted)"}
                                        </label>
                                    </div>
                                )}
                        </FormGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        {editing && <Button className="pull-left" bsStyle="danger" onClick={() => this.removeRole()}>Delete
                            role</Button>}
                        <Button onClick={() => this.close()}>Cancel</Button>
                        <Button bsStyle="primary" type="submit">Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {!roles || roles.fetching && <img src={spinnerImage} alt="Loading..." className="spinner-centered"/>}

            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Role</th>
                    <th>Access</th>
                    <th>Categories</th>
                    <th>Workpackages</th>
                    <th>Windparks</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {roles && roles.items && roles.items.filter(role => role.deleted !== true).map(role => <tr
                    key={role.id}>
                    <td><strong>{role.get("name")}</strong></td>
                    <td>
                        {(role.get("create") && role.get("read")) ? "Full" :
                            role.get("create") ? "Create only" :
                                role.get("read") ? "Read only" : "None"}
                    </td>
                    <td>
                        {Object.values(categories).filter(type => (role.get("categories") || []).includes(type.id)).map(type =>
                            <div key={"cat" + type.id}>
                                {type.get("name")}
                            </div>
                        )}
                    </td>
                    <td>
                        {workpackages.items &&
                            workpackages.items.filter(a => !a.get("deleted")
                                && (role.get("workpackages") || []).includes(a.id))
                                .map(item => <div key={item.id}>
                                        {item.get("name")}
                                    </div>
                                )}
                    </td>
                    <td>
                        {windparks.filter(a => !a.get("deleted")
                            && (role.get("windparks") || []).includes(a.id))
                            .map(item => <div key={item.id}>
                                    {item.get("name")}
                                </div>
                            )}
                    </td>
                    <td>
                        <Button onClick={() => this.editRole(role)}>Edit</Button>
                    </td>
                </tr>)}
                </tbody>
            </table>

            <br/><br/><br/>

            <div className="card-body">

                <Button bsStyle="primary" onClick={() => this.setState({modalOpen: true})}>Add Role</Button>

            </div>

        </>
    }

}


export default connect(state => ({
    workpackages: state.workpackages.items[state.projects.active] || {},
    roles: state.roles.items[state.projects.active],
    categories: state.registrationTypes.items || {},
    windparks: Object.values(state.projects.settings.windparks || {}),
    workpackageRequired: state.projects.active && state.projects.items[state.projects.active]
        && state.projects.items[state.projects.active].get("workpackageRequired") || false,
}), dispatch => ({
    addNewRole: (projectId, values) => dispatch(WPActions.newRole(projectId, values)),
    editRole: (projectId, wpId, name) => dispatch(WPActions.editRole(projectId, wpId, name)),
}))(ProjectRoles);

