import * as Action from '../actions/maps'
import {PENDING, FULFILLED} from '../actions/constants';
import update from 'immutability-helper';

export function maps(state = {
    items: {},
    pointTypes: {},
    active: null,
    fetching: false,
}, action) {
    switch (action.type) {
        case Action.FETCH_MAP_OVERLAY_POLYS + PENDING:
            return update(state, {
                items: {
                    $merge: {
                        [action.meta.projectId]: {
                            fetchingPolys: true,
                        }
                    }
                }
            });
        case Action.FETCH_MAP_OVERLAY_POLYS + FULFILLED:
            return update(state, {
                items: {
                    [action.meta.projectId]: {
                        fetchingPolys: {$set: false},
                        polys: {$set: action.payload}
                    }
                }
            });
        case Action.FETCH_MAP_OVERLAY_POINTS + PENDING:
            return update(state, {
                fetching: {$set: true},
                items: {
                    $merge: {
                        [action.meta.projectId]: {
                            fetchingPoints: true,
                        }
                    }
                }
            });
        case Action.FETCH_MAP_OVERLAY_POINTS + FULFILLED:
            return update(state, {
                fetching: {$set: false},
                items: {
                    [action.meta.projectId]: {
                        fetchingPoints: {$set: false},
                        points: {$set: action.payload}
                    }
                }
            });
        case Action.SAVE_POINT + PENDING:
            if (!action.meta.isNewPoint) {
                return update(state, {
                    items: {
                        [action.meta.projectId]: {
                            points: {
                                $apply: items => {
                                    return items.filter(a => a.id !== action.meta.id)
                                }
                            }
                        }
                    }
                });
            }
            return state;
            break;
        case Action.SAVE_POINT + FULFILLED:
            return update(state, {
                items: {
                    [action.payload.get("project").id]: {
                        points: {$push: [action.payload]}
                    }
                }
            });
        case Action.REMOVE_POINT + FULFILLED:
            return update(state, {
                items: {
                    [action.payload.get("project").id]: {
                        points: {
                            $apply: items => {
                                return items.filter(a => a.id !== action.payload.id)
                            }
                        }
                    }
                }
            });
        case Action.FETCH_POINT_TYPES + FULFILLED:
            return update(state, {
                pointTypes: {$set: action.payload}
            });
        default:
            return state;
    }
};
