import Parse from 'parse';
import * as ProjectActions from "./projects";
import HSERegistration from "../models/HSERegistration";

export const FETCH_REGISTRATIONS = "FETCH_REGISTRATIONS";
export const FETCH_REGISTRATIONS_FOR_EXPORT_START = "FETCH_REGISTRATIONS_FOR_EXPORT_START";
export const FETCH_REGISTRATIONS_FOR_EXPORT = "FETCH_REGISTRATIONS_FOR_EXPORT";
export const FETCH_REGISTRATION_IMAGES = "FETCH_REGISTRATION_IMAGES";
export const ADD_IMAGE = "ADD_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const CLEAR_CACHE = "CLEAR_CACHE";
export const SET_REGISTRATION_CATEGORY_FILTER = "SET_REGISTRATION_CATEGORY_FILTER";
export const SET_REGISTRATION_SEARCH_FILTER = "SET_REGISTRATION_SEARCH_FILTER";

const Project = Parse.Object.extend("Project");

export function fetchRegistrations(project, limit, skip){
    return (dispatch, state) => {
        let query = new Parse.Query("HSERegistration")
            .descending("createdAt") //trackingNumber
            .equalTo("project", new Project({id: project}))
            .notEqualTo("deleted", true)
            .include("user")
            .include("user.userInfo")
            .include("users")
            .include("users.userInfo")
            .include("type")
            .include("registrationCategory")
            .include("registrationPriority")
            .include("registrationActivity")
            .include("registrationDanger")
            .include("workpackage.name")
            .include("mapPoint.description")
            .limit(limit)
            .skip(skip);
        const {registrations} = state();
        if(registrations.registrationCategoryFilter) {
            query.equalTo("registrationCategory", registrations.registrationCategoryFilter);
            console.log("filtering on: ", registrations.registrationCategoryFilter);
        }
        if(registrations.registrationSearchFilter
            && registrations.registrationSearchFilter !== "") {
            // !! Same filter in projects.js for count
            query.fullText('description', registrations.registrationSearchFilter);
            console.log("searching for: ", registrations.registrationSearchFilter);
        }
        dispatch({
            type: FETCH_REGISTRATIONS,
            payload: query
                .find()
                .then(objects => objects.reduce((o, n) => {
                    o[n.id] = n;
                    return o;
                }, {}))
        });
    }
}
export function fetchRegistrationsForExport(projects, fromDate, toDate){
    return async (dispatch, state) => {

        dispatch({type: FETCH_REGISTRATIONS_FOR_EXPORT_START});

        let registrationPromises = projects.map(projectId => {
            let query = new Parse.Query("HSERegistration")
                .descending("createdAt") //trackingNumber
                .equalTo("project", new Project({id: projectId}))
                .notEqualTo("deleted", true)
                .include("user")
                .include("user.userInfo")
                .include("type")
                .include("registrationCategory")
                .include("registrationPriority")
                .include("registrationActivity")
                .include("registrationDanger")
                .include("workpackage.name")
                .limit(9000);

            if (fromDate) {
                query = query.greaterThanOrEqualTo("createdAt", fromDate.toDate());
            }
            if (toDate) {
                query = query.lessThanOrEqualTo("createdAt", toDate.toDate());
            }

            return query.find();
        });

        let registrations = (await Promise.all(registrationPromises))
            .reduce((acc, curr) => acc.concat(curr || []), []);

        dispatch({
            type: FETCH_REGISTRATIONS_FOR_EXPORT,
            payload: registrations
        });
    }
}
export function fetchRegistrationsIfNeeded(project, force){
    return (dispatch, state) => {
        const {registrations} = state();
        const {pagination} = registrations;
        if(force || (!registrations.fetched && !registrations.fetching)){
            dispatch(fetchRegistrations(project, pagination.limit, pagination.skip));
        }
    };
}

export function filterOnRegistrationCategory(category, project){
    return (dispatch) => {
        // dispatch({type: CLEAR_CACHE});
        dispatch({type: SET_REGISTRATION_CATEGORY_FILTER, payload: category});
        dispatch(fetchRegistrationsIfNeeded(project.id, true));
        dispatch(ProjectActions.countRegistrations(project.id, true));
        // dispatch(setActiveRegistration(null));
    };
}

export function filterOnSearch(category, project){
    return (dispatch) => {
        // dispatch({type: CLEAR_CACHE});
        dispatch({type: SET_REGISTRATION_SEARCH_FILTER, payload: category});
        dispatch(fetchRegistrationsIfNeeded(project.id, true));
        dispatch(ProjectActions.countRegistrations(project.id, true));
        // dispatch(setActiveRegistration(null));
    };
}

export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export function setActivePage(page){
    return (dispatch, state) => {
        dispatch({
            type: SET_ACTIVE_PAGE,
            payload: page
        });
        dispatch(fetchRegistrationsIfNeeded(state().projects.active))
    }
}

export function fetchImages(registrationId){
    return (dispatch, state) => {
        const {registrations} = state();
        let registration = registrations.items[registrationId];
        if (registration && !state().registrations.images[registrationId]) {
            dispatch({
                type: FETCH_REGISTRATION_IMAGES,
                meta: {registrationId: registrationId},
                payload: registration.relation("images")
                    .query()
                    .ascending("order")
                    .find()
                    // .then(objects => objects.reduce((o, n) => {o[n.id] = n; return o;}, {}))
            });
        }
    }
}

export const SET_ACTIVE_REGISTRATION = "SET_ACTIVE_REGISTRATION";
export function setActiveRegistration(registrationId, force){
    return (dispatch, state) => {
        const {registrations, projects} = state();
        if (registrationId !== undefined &&
            registrationId !== "new" && (
            force ||
            !registrations.active.item ||
            registrations.active.item.id !== registrationId)
        ) {
            dispatch({
                type: SET_ACTIVE_REGISTRATION,
                payload: new Parse.Query("HSERegistration")
                    .get(registrationId),
            });

            dispatch(fetchImages(registrationId))
        } else if (registrationId === "new"){
            let registration = new HSERegistration();
            registration.set("project", projects.items[projects.active]);
            // if (state.registrationTypes && state.registrationTypes.items && state.registrationTypes.items["jPHr8pdNKr"]) {
            //     registration.set("type", state.registrationTypes.items["jPHr8pdNKr"]);
            registration.set("registrationCategory", new Parse.Object("RegistrationCategory", {id: "jPHr8pdNKr"}) );
            // }
            registration.set("open", true);
            registration.set("user", Parse.User.current());
            dispatch({
                type: SET_ACTIVE_REGISTRATION + "_FULFILLED",
                payload: registration,
            });
            dispatch({
                type: FETCH_REGISTRATION_IMAGES + "_FULFILLED",
                meta: {registrationId: "new"},
                payload: [],
            });
        }
    }
}

export const UPDATE_REGISTRATION_OBJECT = "UPDATE_REGISTRATION_OBJECT";
export function updateRegistrationObject(registration){
    return {
        type: UPDATE_REGISTRATION_OBJECT,
        registration,
    }
}

export const FETCH_VERSION_LOGS = "FETCH_VERSION_LOGS";
export function fetchVersionLogs(registration){
    return {
        type: FETCH_VERSION_LOGS,
        payload: new Parse.Query("HSERegistrationVersionLog")
            .include("user")
            .include("user.userInfo")
            .equalTo("registration", registration)
            .descending("createdAt")
            .find()
    };
}

export function addImage(image, registrationId) {
    return {
        type: ADD_IMAGE,
        meta: {registrationId: registrationId || "new"},
        payload: image,
    }
}