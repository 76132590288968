import React from 'react';
import {connect} from "react-redux";
import {Button, Col, FormGroup, Row} from 'react-bootstrap';
import Parse from 'parse';
import * as ProjectAction from "../actions/projects";

class ProjectMapZoomField extends React.Component {

    constructor(props) {
        super(props);
        let googleMapsZoomLevel = props.project.get("googleMapsZoomLevel");
        this.state = {
            googleMapsZoomLevel,
        }
    }

    componentWillReceiveProps(props) {
        let googleMapsZoomLevel = props.project.get("googleMapsZoomLevel");
        this.setState({
            googleMapsZoomLevel,
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value.replace(/[^\d]/g, "")});
    }

    save(increment) {

        let project = this.props.project;
        let googleMapsZoomLevel = parseInt((this.state.googleMapsZoomLevel || 13) + (increment ? 1 : -1));
        project.set("googleMapsZoomLevel", googleMapsZoomLevel);
        this.props.saveProject(project);

    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col xs={3}>
                        <strong>Zoom Level</strong>
                    </Col>
                    <Col xs={6}>
                        <Button onClick={() => this.save(false)}>-</Button>
                        <span> {this.state.googleMapsZoomLevel || 13} </span>
                        <Button onClick={() => this.save(true)}>+</Button>
                    </Col>
                </Row>
            </FormGroup>
        );
    }
}

export default connect(state => ({}), dispatch => ({
    saveProject: (project) => dispatch(ProjectAction.saveProject(project, true)),
}))(ProjectMapZoomField);