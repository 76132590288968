import React from 'react';
import {connect} from "react-redux";
import * as Action from '../actions/registrationTypes'
import * as RegistrationAction from "../actions/registrations";

class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
        }
    }

    handleChange(event) {
        this.setState({search: event.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.setActiveSearch(this.state.search, this.props.project);
        return false;
    }

    handleCancel(e) {
        this.setState({search: ""});
        this.props.setActiveSearch("", this.props.project);
    }

    render() {

        return (
            <div style={this.props.style}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <input type={"text"} value={this.state.search} placeholder={"Search..."}
                           onChange={(e) => this.handleChange(e)}/>
                    <button type={"submit"}>
                        Search
                    </button>
                    <button onClick={() =>  this.handleCancel()}>Cancel</button>
                </form>
            </div>
        )
    }
}

export default connect(state => ({
    activeSearch: state.registrations.registrationSearchFilter,
}), dispatch => ({
    setActiveSearch: (s, p) => dispatch(RegistrationAction.filterOnSearch(s, p))
}))(SearchBar);