import * as Action from '../actions/registrationTypes'
import {PENDING, FULFILLED} from '../actions/constants';

export function registrationTypes (state = {
    items: {},
    fetching: false,
    fetched: false,
    active: null,
    priorities: [],
}, action) {
    switch(action.type){
        case Action.FETCH_REGISTRATION_TYPES + PENDING:
            return Object.assign({}, state, {
                fetching: true,
            });
        case Action.FETCH_REGISTRATION_TYPES + FULFILLED:
            return Object.assign({}, state, {
                fetching: false,
                fetched: true,
                items: action.payload
            });
        case Action.SET_ACTIVE_TYPE:
            let activeType = state.items[action.payload.id];
            return Object.assign({}, state, {
                active: activeType,
                priorities: [
                    activeType.get("priority1"),
                    activeType.get("priority2"),
                    activeType.get("priority3"),
                ]
            });
        default:
            return state;
    }
};
