import React from 'react';

export const CALLBACK_VALUES = ["No callback required", "Callback required"];

export default class CallbackSelector extends React.Component {

    handleChange(event) {
        this.props.registration.set("callback", parseInt(event.target.value, 10))
    }

    render() {

        const {editing, registration} = this.props;

        return editing ? (
            <select defaultValue={registration.getCallback() ? 1 : 0} onChange={(e) => {this.handleChange(e)}}>
                <option value="0">{CALLBACK_VALUES[0]}</option>
                <option value="1">{CALLBACK_VALUES[1]}</option>
            </select>
        ) : (
            registration.getCallback() ? (
                <span className="callback-required">{CALLBACK_VALUES[1]}</span>
            ) : (
                <span>{CALLBACK_VALUES[0]}</span>
            )
        )

    }
}