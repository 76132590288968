import React from "react";
import {MapLegendPart} from "./MapLegend";
import {Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal, Row} from "react-bootstrap";
import spinnerImage from "../resources/images/spinner.gif";
import {parseHex} from "./RegistrationMapOverlayBuilder";

export default class MapPolyEditor extends React.Component {

    constructor(props) {
        super(props);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    edit(description, poly) {
        this.setState({
            "editPoly": description,
            "fillColor": poly.parseObject.get("fillColor"),
            "strokeColor": poly.parseObject.get("strokeColor"),
            "strokeWidth": poly.parseObject.get("strokeWidth"),
        });
    }

    savePoly() {
        console.log(this.state);

        let polysGrouped = this.polysGrouped();

        let description = this.state.editPoly;
        let promises = [];
        for (let poly of polysGrouped[description]) {
            poly.parseObject.set("fillColor", this.state.fillColor);
            poly.parseObject.set("strokeColor", this.state.strokeColor);
            poly.parseObject.set("strokeWidth", parseFloat(this.state.strokeWidth));
            poly.parseObject.set("isPolygon", this.state.isPolygon);
            promises.push(poly.parseObject.save());
        }
        Promise.all(promises).then(() => {
            window.location.reload();
        })


    }

    polysGrouped() {
        let polysGrouped = {};

        if (this.props && this.props.polys) {
            let polys = this.props.polys;

            for (let poly of polys) {
                if (!polysGrouped[poly.description]) polysGrouped[poly.description] = [];
                polysGrouped[poly.description].push(poly);
            }

        }
        return polysGrouped;
    }

    renamePolys(description) {

        let polysGrouped = this.polysGrouped();

        let newDescription = window.prompt("Enter the new description:", description);

        if (!newDescription || newDescription === "" || newDescription.trim() === "") {
            alert("Please enter a description");
            return;
        }
        let overwriteStyle = null;
        if (polysGrouped[newDescription]) {
            overwriteStyle = polysGrouped[newDescription][0];
            if (!window.confirm("This description is already in use, the two descriptions will be merged and the style of the existing description will be used. This can not be undone. Continue?")) return;
        }

        let promises = [];
        for (let poly of polysGrouped[description]) {
            poly.parseObject.set("description", newDescription);
            if (overwriteStyle) {
                poly.parseObject.set("fillColor", overwriteStyle.parseObject.get("fillColor"));
                poly.parseObject.set("strokeColor", overwriteStyle.parseObject.get("strokeColor"));
                poly.parseObject.set("strokeWidth", overwriteStyle.parseObject.get("strokeWidth"));
                poly.parseObject.set("isPolygon", overwriteStyle.parseObject.get("isPolygon"));

            }
            promises.push(poly.parseObject.save());
        }
        Promise.all(promises).then(() => {
            window.location.reload();
        })

    }

    render() {

        let polysGrouped = this.polysGrouped();

        return <>
            <table className="table table-condensed">
                <thead>
                <tr>
                    <th style={{width: '120px'}}></th>
                    <th>Description</th>
                    <th>Items</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(polysGrouped).map((description, nr) => {
                    let polys = polysGrouped[description];
                    return <tr key={"pg" + nr}>
                        <td><MapLegendPart {...polys[0]} /></td>
                        <td>{description}</td>
                        <td>{polys.length}</td>
                        <td>
                            <Button bsStyle="warning"
                                    onClick={() => this.renamePolys(description, polys[0])}><Glyphicon
                                glyph="edit"/></Button>
                            {" "}
                            <Button bsStyle="warning"
                                    onClick={() => this.edit(description, polys[0])}><Glyphicon
                                glyph="eye-open"/></Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>

            {this.state && this.state.editPoly &&
                <Modal show={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>"{this.state.editPoly}" bewerken</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col sm={3}>
                                    <br />
                                    <MapLegendPart fillColor={this.state.fillColor} fillOpacity={parseHex(this.state.fillColor).opacity} isPolygon={true}/>
                                </Col>
                                <Col sm={9}>
                                    <ControlLabel>Fill Color</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="fillColor"
                                        value={this.state.fillColor}
                                        placeholder="#000000"
                                        onChange={this.handleChange.bind(this)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <br />
                                    <MapLegendPart fillColor={parseHex(this.state.strokeColor).color} fillOpacity={parseHex(this.state.strokeColor).opacity} isPolygon={true}/>
                                </Col>
                                <Col sm={9}>
                                    <ControlLabel>Stroke Color</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="strokeColor"
                                        value={this.state.strokeColor}
                                        placeholder="#000000"
                                        onChange={this.handleChange.bind(this)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <br />
                                    <MapLegendPart strokeColor={parseHex(this.state.strokeColor).color} strokeOpacity={parseHex(this.state.strokeColor).opacity} strokeWidth={parseFloat(this.state.strokeWidth)} isPolygon={true}/>
                                </Col>
                                <Col sm={9}>
                                    <ControlLabel>Stroke Width</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="strokeWidth"
                                        value={this.state.strokeWidth}
                                        placeholder="1"
                                        onChange={this.handleChange.bind(this)}/>
                                </Col>
                            </Row>
                        </Form>
                        <Modal.Footer>
                            <Button onClick={() => window.location.reload()}>Cancel</Button>
                            <Button onClick={() => this.savePoly()} bsStyle="primary">Save</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>}
        </>;
    }

}