import React from 'react';
import {Button, FormGroup, FormControl, ControlLabel, Modal, Form, Alert} from 'react-bootstrap';

export default class ModalReaction extends React.Component {
    constructor(){
        super();

        this.state = {
            alert: null,
            showModal: false,
            reaction: "",
        };
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.closeModal}>
                <Form onSubmit={this.save}>
                    <Modal.Header closeButton>
                        <Modal.Title>Provide a reaction</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.alert && this.state.alert !== "" &&
                        <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                            <h4>Error</h4>
                            <p>{this.state.alert}</p>
                        </Alert>
                        }
                        <FormGroup
                            controlId="formBasicText"
                        >
                            <ControlLabel>Reaction</ControlLabel>
                            <FormControl
                                type="text"
                                name="reaction"
                                value={this.state.reaction}
                                placeholder="Reaction"
                                onChange={this.handleChange.bind(this)}
                            />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.close()}>Cancel</Button>
                        <Button onClick={() => this.props.close(this.state.reaction)} bsStyle="primary">Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    }
}