import React from 'react';
import {connect} from "react-redux";
import * as Action from '../actions/registrationTypes'
import * as RegistrationAction from "../actions/registrations";

class TypeFilter extends React.Component {

    constructor(props) {
        super(props);
        props.fetchTypes();
    }

    handleChange(event) {
        const {types} = this.props;
        let type = types[event.target.value];
        this.props.setActiveType(type, this.props.project);
    }

    render() {

        const {types, activeType} = this.props;
        let activeTypeId = null;
        if(activeType && activeType.id)activeTypeId = activeType.id;
        // let registrationType = registration.get("type");
        return (
            <div style={this.props.style}>
                <select defaultValue={activeTypeId} onChange={(e) => {
                    this.handleChange(e)
                }}>
                    <option key={null} value={null}>
                        All types
                    </option>
                    {Object.values(types).map((type, index) => {
                        return (
                            <option key={"type" + index} value={type.id}>
                                {type.get("name")}
                            </option>
                        )
                    })}
                </select>
            </div>
        )
    }
}

export default connect(state => ({
    types: state.registrationTypes.items,
    activeType: state.registrations.registrationCategoryFilter,
}), dispatch => ({
    fetchTypes: project => dispatch(Action.fetch()),
    setActiveType: (type, project) => dispatch(RegistrationAction.filterOnRegistrationCategory(type, project))
}))(TypeFilter);