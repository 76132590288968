import Parse from 'parse';

export const FETCH_WORKPACKAGES = "FETCH_WORKPACKAGES";
export const NEW_WORKPACKAGE = "NEW_WORKPACKAGE";
export const EDIT_WORKPACKAGE = "EDIT_WORKPACKAGE";
export const REMOVE_WORKPACKAGE = "REMOVE_WORKPACKAGE";

export function fetch(projectId){
    return {
        type: FETCH_WORKPACKAGES,
        meta: {
            projectId
        },
        payload: new Parse.Query("Workpackage")
            .equalTo("project", new Parse.Object('Project', { id: projectId }))
            .notEqualTo("deleted", true)
            .ascending("name")
            .find()
    };
}
export function newWorkpackage(projectId, name, descriptionText){
    return {
        type: NEW_WORKPACKAGE,
        meta: {
            projectId
        },
        payload: new Parse.Object("Workpackage")
            .set("project", new Parse.Object('Project', { id: projectId }))
            .set("name", name)
            .set("descriptionText", descriptionText)
            .save()
    };
}
export function editWorkpackage(projectId, workpackage){
    return {
        type: EDIT_WORKPACKAGE,
        meta: {
            projectId
        },
        payload: workpackage
            .save()
    };
}