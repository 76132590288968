import ReactClass from 'create-react-class';
import React from 'react';
import {Button, Col, FormGroup, FormControl, Row, Alert, Modal} from 'react-bootstrap';
import {connect} from "react-redux";
import * as UserAction from "./actions/users";
import ModalAddExistingUser from './modalAddExistingUser'
import ModalUser from './modalUser'
import spinnerImage from "./resources/images/spinner.gif";

let MemberSelect = ReactClass({

    getInitialState() {
        return {
            newUserMail: "",
        };
    },

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    },

    add() {
        if (this.state.newUserMail !== "") {
            this.props.addUserToProject(this.state.newUserMail, this.props.project);
            this.setState({"newUserMail": ""});
        }
    },

    render() {
        return (
            <div className="card-body">
                <form>
                    {this.props.newMembership.existing &&
                        <Alert bsStyle="danger">
                            <h4>Error</h4>
                            <p>This user is already added to this project.</p>
                        </Alert>
                    }
                    <Row>
                        <Col sm={8}><h4>Add user:</h4></Col>
                    </Row>
                    <Row>
                        <FormGroup controlId="formBasicText">
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    name="newUserMail"
                                    value={this.state.newUserMail}
                                    placeholder="E-mail address"
                                    onChange={this.handleChange}/>
                            </Col>
                            <Col sm={2}>
                                {this.props.newMembership.checking ? (
                                    <img src={spinnerImage} alt="Loading..."/>
                                ) : (
                                    <Button bsStyle="primary" type="submit" onClick={this.add}>Add</Button>
                                )}
                            </Col>
                        </FormGroup>
                    </Row>
                    {/*<Row>*/}
                    {/*    <h5>Log</h5>*/}
                    {/*    <pre>*/}
                    {/*        {this.props.log.join("\n\n")}*/}
                    {/*    </pre>*/}
                    {/*</Row>*/}
                    <br className="clear-fix"/>
                    {this.props.newMembership.checked && this.props.newMembership.existingUser && (
                        <ModalAddExistingUser mail={this.props.newMembership.mail}
                                              user={this.props.newMembership.existingUser}
                                              project={this.props.project}/>
                    )}
                    {this.props.newMembership.checked &&
                        this.props.newMembership.existingUser === undefined &&
                        !this.props.newMembership.existing && (
                            <ModalUser mail={this.props.newMembership.mail} error={this.props.newMembership.error}
                                       project={this.props.project} show={true}/>
                        )}
                    {this.props.newMembership.newUserEmail && <Modal show={true} onHide={() => this.props.cancelAddUserToProject()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New user</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert bsStyle={"success"}>
                                        The new user can set a password using the following link:<br /> https://gemba.ventolines.nl/set-password?email={encodeURIComponent(btoa(this.props.newMembership.newUserEmail))}
                                    </Alert>
                                </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.props.cancelAddUserToProject()}>
                                Close
                            </Button>
                        </Modal.Footer>
                        </Modal>}
                </form>
            </div>
        );
    }

});
export default connect(state => ({
        newMembership: state.users.newMembership,
        log: state.users.log,
    }
), dispatch => ({
    addUserToProject: (mail, project) => dispatch(UserAction.addUserToProject(mail, project)),
    cancelAddUserToProject: () => dispatch(UserAction.cancelAddUserToProject()),
    // addMembership: (membership) => dispatch(UserAction.addMembership(membership)),
}))(MemberSelect);