import Parse from 'parse';

// Parse.initialize('tHdWjw2Xc3fikXlwfP7sVvutOHOdcqUngWPYMdlk', 'XZKB72wG8Eq6NVbtizICL2yHPOBnrQcYUMOkqjog');
// Parse.serverURL = 'https://pg-app-sm6e4a3uq3z2j36luk2yh1dcjpwc5t.scalabl.cloud/1/';


Parse.initialize('hseq', 'DBJKfj4CtPDq3cE5dB3EuYb7');
if (process.env.REACT_APP_MASTER_KEY) Parse.masterKey = process.env.REACT_APP_MASTER_KEY;
Parse.serverURL = 'https://parse.hseq.webzites.link';
// Parse.serverURL = 'http://localhost:1337';

// Parse.User.become("r:3ef34ef50f099d440f294702d0f89f22").catch(console.error);