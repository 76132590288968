import React from "react";
import Parse from 'parse';
import {connect} from 'react-redux';
import {Button} from "react-bootstrap";
import * as RegistrationAction from "../actions/registrations";

class AddImage extends React.Component {

    handleFileChange = (event) => {
        let files = event.target.files;
        let {dispatch, images, registration, addImage} = this.props;

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let i = Object.values(images[registration.id] || []).length + 1;
            let reader = new FileReader();
            reader.onload = (event) => {
                let image = new Parse.Object("RegistrationImage");
                image.set("project", registration.get("project"));
                image.set("user", Parse.User.current());
                image.set("order", i);
                image.set("file", new Parse.File(file.name, {base64: event.target.result}));
                image.set("localUrl", URL.createObjectURL(file));
                addImage(image, registration.id);
            };
            reader.readAsDataURL(file);
        }
    }

    render() {

        return <>
            <label htmlFor="input_file" className="btn btn-default">
                Add Image
            </label>
            <input
                type="file"
                id="input_file"
                accept=".jpg,.jpeg,.png,.heic,.heif,.tiff,.bmp,.gif,.webp"
                onChange={this.handleFileChange}
                multiple={true}
                style={{display: "none"}}
            />
            <br style={{clear: "both"}}/><br />
        </>
    }
}

export default connect(state => ({
    images: state.registrations.images
}), dispatch => ({
    addImage: (image, registrationId) => dispatch(RegistrationAction.addImage(image, registrationId)),
}))(AddImage);