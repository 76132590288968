import React from 'react';
import {connect} from 'react-redux';
import * as ProjectAction from '../actions/projects'
import Parse from "parse";
import {countRegistrations} from "../actions/projects";
import moment from "moment";
import update from 'immutability-helper';

export default class BillingPage extends React.Component {

    state = {
        loading: true,
        projects: [],
        activeProjects: [],
        inactiveProjects: [],
    };

    componentWillMount() {
        this.loadData();
    }

    loadData() {

        console.log("loadData()");

        let currentQuarterStart = moment("1 January 2020");

        let testProjects = [
            "r8y8YE5P4N",
            "OaSqkJfDRw",
            "JjLtLkZcxB",
        ];

        let promises = [];
        let allProjects = [];

        let _this = this;
        new Parse.Query("Project")
            .ascending("name")
            .limit(1000)
            .find()
            .then(projects => {
                allProjects = projects;
                for (let i = 0; i < projects.length; i++) {
                    let project = projects[i];

                    //Count Regs
                    promises.push(new Parse.Query(Parse.Object.extend("HSERegistration"))
                        .equalTo("project", project)
                        .count()
                        .then(count => {
                            allProjects = update(allProjects, {
                                    [i]: {
                                        registrationsCount: {$set: count}
                                    }
                            })
                        }).catch(console.error));

                    // //First Reg
                    promises.push(new Parse.Query("HSERegistration")
                        .equalTo("project", project)
                        .ascending("createdAt")
                        .first().then(registration => {
                            if (registration) {
                                allProjects = update(allProjects, {
                                        [i]: {
                                            firstRegistration: {$set: registration}
                                        }
                                })
                            }
                        }));

                    // //Last reg
                    promises.push(new Parse.Query("HSERegistration")
                        .equalTo("project", project)
                        .descending("createdAt")
                        .first().then(registration => {
                            if (registration) {
                                allProjects = update(allProjects, {
                                        [i]: {
                                            lastRegistration: {$set: registration}
                                        }
                                })
                            }
                        }));
                }

                console.log(promises);

                return Promise.all(promises);

            }).then(() => {

                console.log("All promises resolved");

            const projects = allProjects;

            let activeProjects = [];
            let inactiveProjects = [];
            for (let i = 0; i < projects.length; i++) {
                let project = projects[i];

                if (testProjects.indexOf(project.id) === -1 &&
                    project.registrationsCount > 0 &&
                    moment(project.lastRegistration.get("createdAt")).diff(currentQuarterStart) > 0
                ) {
                    activeProjects.push(project);

                } else {
                    inactiveProjects.push(project);

                }

            }

            _this.setState(update(_this.state, {
                loading: {$set: false},
                activeProjects: {
                    $set: activeProjects
                },
                inactiveProjects: {
                    $set: inactiveProjects
                }
            }))

        }).catch(console.error);

    }

    render() {

        const {loading} = this.state;

        return (
            <div className="card">
                <div className="card-heading">
                    <h2>
                        Billing
                    </h2>
                </div>
                {loading && <h3>loading...</h3>}
                {["activeProjects", "inactiveProjects"].map(type =>
                    <div key={type}>
                        <h2>{type}</h2>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>Project</th>
                                <th>Created</th>
                                <th>Last Updated</th>
                                {/*<th>Users</th>*/}
                                <th>Registrations</th>
                                <th>First registration</th>
                                <th>Last registration</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state[type].map(project => <tr key={project.id}>
                                <td>{project.get("name")}</td>
                                <td>{moment(project.get("createdAt")).format("D MMMM YYYY")}</td>
                                <td>{moment(project.get("updatedAt")).format("D MMMM YYYY")}</td>
                                <td>
                                    {project.registrationsCount}
                                </td>
                                <td>
                                    {project.firstRegistration && moment(project.firstRegistration.get("createdAt")).format("D MMMM YYYY")}
                                </td>
                                <td>
                                    {project.lastRegistration && moment(project.lastRegistration.get("createdAt")).format("D MMMM YYYY")}
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="clearfix"/>
            </div>
        );
    }

}