import React from 'react';
import ProjectSettings from "../components/ProjectSettings";

export default class SettingsPage extends React.Component {


    render() {

        return (
            <div className="card">
                <div className="card-heading">
                        <h2>
                            Settings
                        </h2>
                </div>
                <div className="card-body">
                    <ProjectSettings />
                </div>;
            </div>
        );
    }
}