import React from 'react';
import {connect} from 'react-redux';
import * as UserAction from '../actions/users';
import {Button, FormGroup, FormControl, ControlLabel, Form, Alert, Col, Checkbox} from 'react-bootstrap';
import update from 'immutability-helper';

class User extends React.Component {
    constructor() {
        super();

        this.state = {
            alert: {
                show: false,
                style: "info",
                title: "Success",
                text: "",
            },
            acceptTerms: false,
            message: null,
            userInfoFetched: false,
            userInfoSet: false,
            userInfo: {
                name: "",
                phonenumber: "",
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    loadData() {
        if (!this.state.userInfoFetched && this.props.currentUser) {
            this.setState(update(this.state, {userInfoFetched: {$set: true}}))

            this.props.fetchCurrentUserInfo();
        }
        if (!this.state.userInfoSet && this.props.currentUser) {
            const {currentUser} = this.props;
            const userInfo = currentUser.get("userInfo");
            this.setState({
                userInfoSet: true,
                userInfo: {
                    name: userInfo.get("name"),
                    phonenumber: userInfo.get("phonenumber"),
                    acceptedTerms: userInfo.get("acceptedTerms"),
                    acceptedTermsDate: userInfo.get("acceptedTermsDate"),
                }
            })
        }
    }

    handleChange(event) {
        this.setState(update(this.state, {
            userInfo: {
                [event.target.name]: {$set: event.target.value}
            }
        }));
    }

    handleCheckboxChange(event) {
        this.setState(update(this.state, {
            [event.target.name]: {$set: event.target.checked},
        }));
    }

    save(event) {
        event.preventDefault();
        const termsVersion = 1;

        let saveUserInfo = {
            ...this.state.userInfo,
        };
        // console.log(saveUserInfo);
        if (this.state.acceptTerms) {
            saveUserInfo.acceptedTerms = termsVersion;
            saveUserInfo.acceptedTermsDate = new Date();
        }
        // console.log(saveUserInfo);

        this.props.save(saveUserInfo);
        this.setState(update(this.state, {
            userInfoSet: {$set: false},
            alert: {
                show: {$set: true},
                text: {$set: "User data updated."}
            }
        }))

        return false;
    }

    render() {
        const {userInfo} = this.state;

        return <>

            {!userInfo.acceptedTerms &&
                <Alert bsStyle={"warning"}>
                    <h4>Please accept the Terms & Conditions to continue</h4>
                </Alert>}

            <div className="card">
                <div className="card-heading">
                    <h2>
                        My Profile
                    </h2>
                </div>
                <div className="card-body">
                    <Form horizontal onSubmit={this.save}>
                        {this.state.alert.show &&
                            <Alert bsStyle={this.state.alert.style} onDismiss={this.handleAlertDismiss}>
                                <h4>{this.state.alert.title}</h4>
                                <p>{this.state.alert.text}</p>
                            </Alert>
                        }

                        <FormGroup controlId="formBasicText">
                            <Col componentClass={ControlLabel} sm={2}>Name</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="name"
                                    value={userInfo.name}
                                    placeholder="Full name"
                                    onChange={this.handleChange}/>
                            </Col>
                        </FormGroup>

                        <FormGroup controlId="formBasicText">
                            <Col componentClass={ControlLabel} sm={2}>Phone number</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="phonenumber"
                                    value={userInfo.phonenumber}
                                    placeholder="+31612345678"
                                    onChange={this.handleChange}/>
                            </Col>
                        </FormGroup>

                        <FormGroup controlId="formBasicText">
                            <Col componentClass={ControlLabel} sm={2}>
                                Accepted Terms & Conditions
                            </Col>
                            <Col sm={10}>
                                {userInfo.acceptedTerms ? <>
                                    Version {userInfo.acceptedTerms} of Terms & Conditions accepted on {" "}
                                    {userInfo.acceptedTermsDate && userInfo.acceptedTermsDate.toLocaleString()}
                                </> : <>
                                    <Checkbox
                                        type="checkbox"
                                        name="acceptTerms"
                                        value={this.state.acceptTerms}
                                        onChange={this.handleCheckboxChange.bind(this)}
                                    >
                                        I have read and accept the Gemba Terms & Conditions
                                    </Checkbox>
                                </>}
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col smOffset={2} sm={10}>
                                <Button bsStyle="primary" type="submit">Save</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        </>;
    }
}

export default connect(state => ({
    currentUser: state.users.current
}), dispatch => ({
    fetchCurrentUserInfo: () => dispatch(UserAction.fetchCurrentUserInfo()),
    save: newData => dispatch(UserAction.updateCurrentUserInfo(newData))
}))(User)