import React from 'react';
import {connect} from "react-redux";

class PrioritySelector extends React.Component {

    handleChange(event) {
        let prioritiesById = this.props.priorities.reduce((o, n) => {o[n.id] = n; return o;}, {});
        this.props.registration.set("registrationPriority", prioritiesById[event.target.value])
        this.forceUpdate();
    }

    render() {
        const {editing, registration, priorities, activeType} = this.props;
        let prioritiesById = this.props.priorities.reduce((o, n) => {o[n.id] = n; return o;}, {});
        let activeId = registration.get("registrationPriority") && registration.get("registrationPriority").id;
        if(!activeId && registration.get("priority") && activeType){
            let priority = parseInt(registration.get("priority"), 10) + 1;
            activeId = activeType.get("priority" + priority) && activeType.get("priority" + priority).id;
            let prioritiesById = this.props.priorities.reduce((o, n) => {o[n.id] = n; return o;}, {});
            this.props.registration.set("registrationPriority", prioritiesById[activeId]);
        }
        return editing ? <>
            <select value={activeId || ""} onChange={(e) => {this.handleChange(e)}}
                    style={{borderColor: activeId ? "" : "red"}}
            >
                <option>None</option>
                {priorities.map((type, index) => {
                    return (
                        <option key={"priority" + index} value={type.id}>
                            {type.get("name")}
                        </option>
                    )
                })}
            </select>
        </> : (
            <div>
                {registration.getPriorityString()}
                <div className="priority-circle"
                     style={{backgroundColor: registration.getPriorityColor()}}/>
            </div>
        )

    }
}

export default connect(state => ({
    activeType: state.registrationTypes.active,
    priorities: state.registrationTypes.priorities,
}), dispatch => ({
}))(PrioritySelector);